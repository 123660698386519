import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Avatar, Grid, Button, TextField, Snackbar, Alert } from '@mui/material';
import { auth, db, storage } from '../firebase';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import EditIcon from '@mui/icons-material/Edit';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState({
    displayName: '',
    phoneNumber: '',
    address: '',
    role: '',
    photoURL: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        // Fetch additional user data from Firestore
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserProfile({
            displayName: user.displayName || data.displayName || '',
            phoneNumber: data.phoneNumber || '',
            address: data.address || '',
            role: data.role || (user.email === 'abrahamwondwosen@gmail.com' ? 'admin' : 'guest'),
            photoURL: user.photoURL || ''
          });
        } else {
          // Initialize with default values if document doesn't exist
          const isAdmin = user.email === 'abrahamwondwosen@gmail.com';
          setUserProfile({
            displayName: user.displayName || '',
            phoneNumber: '',
            address: '',
            role: isAdmin ? 'admin' : 'guest',
            photoURL: user.photoURL || ''
          });
          
          // Create initial user document
          try {
            await setDoc(userDocRef, {
              displayName: user.displayName || '',
              email: user.email,
              role: isAdmin ? 'admin' : 'guest',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            });
          } catch (error) {
            console.error('Error creating initial user profile:', error);
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handlePhotoUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const storageRef = ref(storage, `profile_photos/${user.uid}`);
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);

      await updateProfile(user, { photoURL });
      setUserProfile(prev => ({ ...prev, photoURL }));
      
      setSnackbar({
        open: true,
        message: 'Profile photo updated successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error uploading photo:', error);
      setSnackbar({
        open: true,
        message: 'Error updating profile photo',
        severity: 'error'
      });
    }
  };

  const handleSave = async () => {
    try {
      // Prevent saving if trying to change admin to guest for the admin email
      if (user.email === 'abrahamwondwosen@gmail.com' && userProfile.role === 'guest') {
        setSnackbar({
          open: true,
          message: 'Admin role cannot be changed to guest',
          severity: 'warning'
        });
        return;
      }

      // Update display name in Auth
      if (userProfile.displayName && user.displayName !== userProfile.displayName) {
        await updateProfile(user, { displayName: userProfile.displayName });
      }

      // Get reference to the user document
      const userDocRef = doc(db, 'users', user.uid);
      
      // Check if the document exists
      const docSnap = await getDoc(userDocRef);
      
      // Ensure admin email always has admin role
      const role = user.email === 'abrahamwondwosen@gmail.com' ? 'admin' : userProfile.role;
      
      // Create or update the document
      if (docSnap.exists()) {
        await updateDoc(userDocRef, {
          displayName: userProfile.displayName || user.displayName || '',
          phoneNumber: userProfile.phoneNumber,
          address: userProfile.address,
          role: role,
          updatedAt: new Date().toISOString()
        });
      } else {
        await setDoc(userDocRef, {
          displayName: userProfile.displayName || user.displayName || '',
          phoneNumber: userProfile.phoneNumber,
          address: userProfile.address,
          role: role,
          email: user.email,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      }

      // Update local user state to reflect changes
      setUser(auth.currentUser);
      
      setIsEditing(false);
      setSnackbar({
        open: true,
        message: 'Profile updated successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbar({
        open: true,
        message: 'Error updating profile: ' + error.message,
        severity: 'error'
      });
    }
  };

  if (!user) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="photo-upload"
              type="file"
              onChange={handlePhotoUpload}
              disabled={user?.email !== 'abrahamwondwosen@gmail.com'}
            />
            <label htmlFor="photo-upload">
              <Box sx={{ position: 'relative' }}>
                <Avatar
                  src={userProfile.photoURL}
                  sx={{ 
                    width: 120, 
                    height: 120, 
                    mb: 2, 
                    cursor: user?.email === 'abrahamwondwosen@gmail.com' ? 'pointer' : 'default'
                  }}
                />
                {user?.email === 'abrahamwondwosen@gmail.com' && (
                  <EditIcon 
                    sx={{ 
                      position: 'absolute', 
                      bottom: 20, 
                      right: 0,
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                      boxShadow: 1
                    }} 
                  />
                )}
              </Box>
            </label>
            <Typography variant="h5" gutterBottom>
              {userProfile.role === 'admin' ? (userProfile.displayName || user?.email?.split('@')[0] || 'My Profile') : 'Guest User'}
            </Typography>
            <Typography color="textSecondary" gutterBottom sx={{ textTransform: 'capitalize' }}>
              {userProfile.role}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              {user?.email === 'abrahamwondwosen@gmail.com' && (
                <>
                  {isEditing ? (
                    <Button variant="contained" onClick={handleSave} sx={{ mr: 1 }}>
                      Save
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={() => setIsEditing(true)}>
                      Edit Profile
                    </Button>
                  )}
                  {isEditing && (
                    <Button variant="outlined" onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Display Name"
              value={userProfile.displayName}
              onChange={(e) => setUserProfile(prev => ({ ...prev, displayName: e.target.value }))}
              disabled={!isEditing || user?.email !== 'abrahamwondwosen@gmail.com'}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={user?.email}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              value={userProfile.phoneNumber}
              onChange={(e) => setUserProfile(prev => ({ ...prev, phoneNumber: e.target.value }))}
              disabled={!isEditing || user?.email !== 'abrahamwondwosen@gmail.com'}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Role"
              value={userProfile.role}
              onChange={(e) => {
                // Prevent changing admin to guest for the admin email
                if (user?.email === 'abrahamwondwosen@gmail.com' && e.target.value === 'guest') {
                  setSnackbar({
                    open: true,
                    message: 'Admin role cannot be changed to guest',
                    severity: 'warning'
                  });
                  return;
                }
                setUserProfile(prev => ({ ...prev, role: e.target.value }));
              }}
              disabled={user?.email !== 'abrahamwondwosen@gmail.com'}
              select
              SelectProps={{
                native: true
              }}
            >
              <option value="admin">Admin</option>
              <option value="guest" disabled={user?.email === 'abrahamwondwosen@gmail.com'}>Guest</option>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              value={userProfile.address}
              onChange={(e) => setUserProfile(prev => ({ ...prev, address: e.target.value }))}
              disabled={!isEditing || user?.email !== 'abrahamwondwosen@gmail.com'}
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
