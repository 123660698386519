import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  TableContainer,
  Paper,
  CircularProgress,
  Alert,
  TablePagination,
  Button,
  Card,
  Grid,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Bar, Pie, Line } from "react-chartjs-2";
import "chart.js/auto";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PeopleIcon from '@mui/icons-material/People';
import TrainIcon from '@mui/icons-material/Train';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Configure dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// Set Ethiopian timezone
dayjs.tz.setDefault("Africa/Addis_Ababa");

// Add these new chart components
const LineChart = ({ data }) => (
  <Line
    data={data}
    options={{
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Daily Ticket Sales Trend'
        }
      }
    }}
  />
);

const RoutesSummaryTable = ({ filteredTickets, collections }) => {
  // Group tickets by date
  const ticketsByDate = filteredTickets.reduce((acc, ticket) => {
    const date = dayjs(ticket.timestamp).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(ticket);
    return acc;
  }, {});

  // Sort dates in descending order
  const dates = Object.keys(ticketsByDate).sort((a, b) => dayjs(b).valueOf() - dayjs(a).valueOf());

  // Calculate daily summaries for each route
  const dailySummaries = dates.map(date => {
    const dayTickets = ticketsByDate[date];
    const routeSummaries = Object.values(collections).map(route => {
      const routeTickets = dayTickets.filter(ticket => ticket.route === route);
      
      return {
        route,
        totalTickets: routeTickets.length,
        totalPassengers: routeTickets.reduce((acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10), 0),
        totalDistance: routeTickets.reduce((acc, ticket) => acc + parseFloat(ticket["Distance KM"] || 0, 10), 0),
        totalCharge: routeTickets.reduce((acc, ticket) => acc + parseFloat(ticket["Total Charge"] || 0, 10), 0),
        totalPrice: routeTickets.reduce((acc, ticket) => acc + parseFloat(ticket["Total Price"] || 0, 10), 0),
      };
    });

    // Calculate daily totals across all routes
    const dailyTotals = routeSummaries.reduce((acc, summary) => ({
      totalTickets: acc.totalTickets + summary.totalTickets,
      totalPassengers: acc.totalPassengers + summary.totalPassengers,
      totalDistance: acc.totalDistance + summary.totalDistance,
      totalCharge: acc.totalCharge + summary.totalCharge,
      totalPrice: acc.totalPrice + summary.totalPrice,
    }), {
      totalTickets: 0,
      totalPassengers: 0,
      totalDistance: 0,
      totalCharge: 0,
      totalPrice: 0,
    });

    return {
      date,
      routeSummaries,
      dailyTotals,
    };
  });

  // Calculate grand totals across all dates and routes
  const grandTotals = dailySummaries.reduce((acc, day) => ({
    totalTickets: acc.totalTickets + day.dailyTotals.totalTickets,
    totalPassengers: acc.totalPassengers + day.dailyTotals.totalPassengers,
    totalDistance: acc.totalDistance + day.dailyTotals.totalDistance,
    totalCharge: acc.totalCharge + day.dailyTotals.totalCharge,
    totalPrice: acc.totalPrice + day.dailyTotals.totalPrice,
  }), {
    totalTickets: 0,
    totalPassengers: 0,
    totalDistance: 0,
    totalCharge: 0,
    totalPrice: 0,
  });

  return (
    <Paper sx={{ mt: 3, mb: 3, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Route Summary for Selected Date Range
        </Typography>
      </Box>
      {dailySummaries.map((daySummary, index) => (
        <Box key={daySummary.date} sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
            Date: {dayjs(daySummary.date).format('MMMM D, YYYY')}
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Route</strong></TableCell>
                  <TableCell align="right"><strong>Total Tickets</strong></TableCell>
                  <TableCell align="right"><strong>Total Passengers</strong></TableCell>
                  <TableCell align="right"><strong>Total Distance (KM)</strong></TableCell>
                  <TableCell align="right"><strong>Total Charge ($)</strong></TableCell>
                  <TableCell align="right"><strong>Total Price ($)</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {daySummary.routeSummaries.map((summary) => (
                  <TableRow key={summary.route}>
                    <TableCell>{summary.route}</TableCell>
                    <TableCell align="right">{summary.totalTickets}</TableCell>
                    <TableCell align="right">{summary.totalPassengers}</TableCell>
                    <TableCell align="right">{summary.totalDistance.toFixed(2)}</TableCell>
                    <TableCell align="right">{summary.totalCharge.toFixed(2)}</TableCell>
                    <TableCell align="right">{summary.totalPrice.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                  <TableCell><strong>Daily Total</strong></TableCell>
                  <TableCell align="right"><strong>{daySummary.dailyTotals.totalTickets}</strong></TableCell>
                  <TableCell align="right"><strong>{daySummary.dailyTotals.totalPassengers}</strong></TableCell>
                  <TableCell align="right"><strong>{daySummary.dailyTotals.totalDistance.toFixed(2)}</strong></TableCell>
                  <TableCell align="right"><strong>{daySummary.dailyTotals.totalCharge.toFixed(2)}</strong></TableCell>
                  <TableCell align="right"><strong>{daySummary.dailyTotals.totalPrice.toFixed(2)}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}

      {/* Grand Totals Table */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
          Grand Totals for Selected Date Range
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><strong>Total Tickets</strong></TableCell>
                <TableCell align="right"><strong>Total Passengers</strong></TableCell>
                <TableCell align="right"><strong>Total Distance (KM)</strong></TableCell>
                <TableCell align="right"><strong>Total Charge ($)</strong></TableCell>
                <TableCell align="right"><strong>Total Price ($)</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ backgroundColor: '#e3f2fd' }}>
                <TableCell><strong>{grandTotals.totalTickets}</strong></TableCell>
                <TableCell align="right"><strong>{grandTotals.totalPassengers}</strong></TableCell>
                <TableCell align="right"><strong>{grandTotals.totalDistance.toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>{grandTotals.totalCharge.toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>{grandTotals.totalPrice.toFixed(2)}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

const TicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDateRange, setSelectedDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]); // Date range state
  const [filterType, setFilterType] = useState("daily"); // 'daily', 'week', 'month', 'year', 'specific'
  const [isDataLoading, setIsDataLoading] = useState(false);

  const collections = {
    tickets_getout: "Modjo",
    tickets_getout_Alemgena: "Alemgena",
    tickets_getout_sebeta: "Sebeta",
    tickets_getout_CheffeDonsa: "Cheffe-Donsa",
    tickets_getout_leman: "Lemmen",
    tickets_getout_welliso: "Welliso",
    tickets_getout_Tajji: "Tajji",
    tickets_getout_busa: "Busa",
    tickets_getout_sadeen: "Sadeen",
    tickets_getout_Sululta: "Sululta",
    tickets_getout_Burayu: "Burayu",
  };

  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      // Only fetch data if both dates are selected
      if (!selectedDateRange[0] || !selectedDateRange[1]) {
        setIsDataLoading(false);
        return;
      }
      
      if (isMounted) {
        setIsDataLoading(true);
        setFilteredTickets([]);
      }
      
      try {
        let allTickets = [];
    
        for (const [collectionName, route] of Object.entries(collections)) {
          const datePaths = getDatePaths(selectedDateRange, filterType);
    
          for (const datePath of datePaths) {
            const docRef = doc(db, collectionName, datePath);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
              const data = docSnap.data();
              
              // Function to process a single ticket
              const processTicket = (ticketData, key, childKey = null) => {
                if (ticketData && typeof ticketData === 'object' && (ticketData.Destination || ticketData["Start Station"])) {
                  const parseNumericValue = (value) => {
                    if (typeof value === 'number') return value;
                    if (typeof value === 'string') {
                      return parseFloat(value.replace(',', '.')) || 0;
                    }
                    return 0;
                  };

                  const id = childKey ? 
                    `${route}-${datePath}-${key}-${childKey}` : 
                    `${route}-${datePath}-${key}`;

                  // Get timestamp and convert to Ethiopian time
                  const timestamp = ticketData.timestamp?.toDate?.() ? 
                    dayjs.tz(ticketData.timestamp.toDate(), "Africa/Addis_Ababa") : 
                    dayjs.tz(ticketData.timestamp, "Africa/Addis_Ababa");

                  return {
                    ...ticketData,
                    id: id,
                    route: route,
                    "Total Price": parseNumericValue(ticketData["Total Price"]),
                    "Net Price": parseNumericValue(ticketData["Net Price"]),
                    "Price": parseNumericValue(ticketData["Price"]),
                    "Service Charge": parseNumericValue(ticketData["Service Charge"]),
                    "Distance KM": parseNumericValue(ticketData["distance_km"] || ticketData["Distance KM"]),
                    "Seat Capacity": parseInt(ticketData["Seat Capacity"]) || 0,
                    "Total Charge": parseNumericValue(ticketData["Total Charge"]),
                    timestamp: timestamp,
                    // Format time in 12-hour format for Ethiopian time
                    formattedTime: timestamp.format("hh:mm A"),
                    created_on: ticketData.created_on
                  };
                }
                return null;
              };

              // Process all tickets in the document
              Object.entries(data).forEach(([key, value]) => {
                if (typeof value === 'object') {
                  if (value.Destination || value["Start Station"]) {
                    // Direct ticket object
                    const ticket = processTicket(value, key);
                    if (ticket) allTickets.push(ticket);
                  } else {
                    // Nested ticket objects
                    Object.entries(value).forEach(([childKey, childValue]) => {
                      const ticket = processTicket(childValue, key, childKey);
                      if (ticket) allTickets.push(ticket);
                    });
                  }
                }
              });
            }
          }
        }
    
        if (isMounted) {
          // Sort tickets by timestamp in descending order (latest first)
          const sortedTickets = allTickets.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());
          setTickets(sortedTickets);
          filterTickets(searchTerm, selectedRoute, sortedTickets);
          setIsDataLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) {
          setError(error.message);
          setIsDataLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedDateRange, filterType]);

  const getDatePaths = (dateRange, filterType) => {
    const startDate = dayjs(dateRange[0]);
    const endDate = dayjs(dateRange[1]);

    let datePaths = [];

    switch (filterType) {
      case "week":
      case "month":
      case "year":
      case "specific":
        for (let d = startDate; d.isBefore(endDate) || d.isSame(endDate); d = d.add(1, "day")) {
          datePaths.push(d.format("YYYY-MM-DD"));
        }
        break;
      default:
        datePaths.push(dayjs().format("YYYY-MM-DD"));
        break;
    }

    return datePaths;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterTickets(event.target.value, selectedRoute);
  };

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
    filterTickets(searchTerm, event.target.value);
  };

  const handleDateRangeChange = (newValue) => {
    // Check if both start and end dates are selected
    const [startDate, endDate] = newValue;
    if (startDate && endDate) {
      setSelectedDateRange(newValue);
      setIsDataLoading(true); // Trigger loading when both dates are selected
    } else {
      // Update the date range without triggering loading
      setSelectedDateRange(newValue);
      setIsDataLoading(false); // Ensure loading is not triggered
    }
  };

  const filterTickets = React.useCallback((search, route, allTickets = tickets) => {
    let filtered = [...allTickets];
    
    if (route && route !== "all") {
      filtered = filtered.filter(ticket => ticket.route === route);
    }

    if (search.trim() !== "") {
      const searchLower = search.toLowerCase();
      filtered = filtered.filter((ticket) =>
        Object.entries(ticket).some(([key, value]) => 
          String(value).toLowerCase().includes(searchLower)
        )
      );
    }

    filtered.sort((a, b) => {
      const dateA = new Date(a.created_on || a.timestamp);
      const dateB = new Date(b.created_on || b.timestamp);
      return dateB - dateA;
    });

    setFilteredTickets(filtered);
    setPage(0);
  }, [tickets]);

  const getNestedValue = (obj, key) => {
    if (typeof obj === 'object' && obj !== null) {
      if (obj[key] !== undefined) {
        return parseFloat(obj[key]) || 0;
      }
      return Object.values(obj).reduce((acc, value) => acc + getNestedValue(value, key), 0);
    }
    return 0;
  };

  // Calculate summary totals
  const totalTickets = filteredTickets.length;
  const totalSeatCapacity = filteredTickets.reduce(
    (acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10),
    0
  );
  const totalkm = filteredTickets.reduce(
    (acc, ticket) => acc + parseFloat(ticket["Distance KM"] || 0, 10),
    0
  );
  const totalServiceCharge = filteredTickets.reduce(
    (acc, ticket) => acc + getNestedValue(ticket, "Service Charge"),
    0
  );
  const totalStartStations = 11; // Set to exact number of stations based on service network
  const totalCharge = filteredTickets.reduce(
    (acc, ticket) => acc + getNestedValue(ticket, "Total Charge"),
    0
  );
  const totalPrice = filteredTickets.reduce(
    (acc, ticket) => acc + getNestedValue(ticket, "Total Price"),
    0
  );

  // Add this calculation near your other total calculations
  const mainTotalCharge = filteredTickets.reduce(
    (acc, ticket) => {
      const seatCapacity = parseInt(ticket["Seat Capacity"] || 0, 10);
      const serviceCharge = parseFloat(ticket["Service Charge"] || 0);
      return acc + (seatCapacity * serviceCharge);
    }, 
    0
  );

  const columns = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 200,
      valueGetter: (params) => {
        if (!params.row?.timestamp) return null;
        return params.row.timestamp instanceof Date ? 
          params.row.timestamp : 
          new Date(params.row.timestamp);
      },
      renderCell: (params) => {
        if (!params.row?.timestamp) return "N/A";
        
        try {
          const date = params.row.timestamp instanceof Date ? 
            params.row.timestamp : 
            new Date(params.row.timestamp);
          
          return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          });
        } catch (error) {
          console.error("Date rendering error:", error);
          return "N/A";
        }
      },
    },
    { field: "Destination", headerName: "Destination", width: 150 },
    { field: "Distance KM", headerName: "Distance KM", width: 150 },
    { field: "Level", headerName: "Level", width: 100 },
    { field: "Net Price", headerName: "Net Price", width: 150 },
    { field: "Plate Number", headerName: "Plate Number", width: 150 },
    { field: "Price", headerName: "Price", width: 150 },
    { field: "Route", headerName: "Route", width: 150 },
    { field: "Sales Name", headerName: "Sales Name", width: 150 },
    { field: "Seat Capacity", headerName: "Seat Capacity", width: 150 },
    { field: "Service Charge", headerName: "Service Charge", width: 150 },
    { field: "Start Station", headerName: "Start Station", width: 150 },
    { field: "Total Charge", headerName: "Total Charge", width: 150 },
    { field: "Total Price", headerName: "Total Price", width: 150 },
    { field: "userCode", headerName: "User Code", width: 150 },
  ];

  const data = {
    labels: Object.values(collections),
    datasets: [
      {
        label: "Total Tickets",
        data: Object.values(collections).map(
          (route) =>
            tickets.filter((ticket) => ticket.route === route).length
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Total Passengers",
        data: Object.values(collections).map((route) =>
          tickets
            .filter((ticket) => ticket.route === route)
            .reduce((acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10), 0)
        ),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
      {
        label: "Total Price",
        data: Object.values(collections).map((route) =>
          tickets
            .filter((ticket) => ticket.route === route)
            .reduce((acc, ticket) => acc + getNestedValue(ticket, "Total Price"), 0)
        ),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
      },
    ],
  };
  const pieData = {
    labels: Object.values(collections),
    datasets: [
      {
        label: "Total Tickets",
        data: Object.values(collections).map(
          (route) =>
            tickets.filter((ticket) => ticket.route === route).length
        ),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
      },
    ],
  };

  // Add new data for line chart
  const lineChartData = {
    labels: Array.from({ length: 7 }, (_, i) => 
      dayjs().subtract(6 - i, 'day').format('MMM D')
    ),
    datasets: [
      {
        label: 'Tickets Sold',
        data: Array.from({ length: 7 }, () => 
          Math.floor(Math.random() * 100)
        ),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  // Add new data for additional charts
  const revenueData = {
    labels: Object.values(collections),
    datasets: [
      {
        label: 'Revenue by Route',
        data: Object.values(collections).map((route) =>
          tickets
            .filter((ticket) => ticket.route === route)
            .reduce((acc, ticket) => acc + parseFloat(ticket["Total Price"] || 0), 0)
        ),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      }
    ]
  };

  // Add this to verify Route Statistics data
  const routeStatisticsData = {
    labels: Object.values(collections),
    datasets: [
      {
        label: "Total Tickets",
        data: Object.values(collections).map(
          (route) => tickets.filter((ticket) => ticket.route === route).length
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      // ... other datasets ...
    ],
  };

  console.log('Route Statistics data:', routeStatisticsData);

  // Add these new styles
  const styles = {
    pageContainer: {
      backgroundColor: '#f5f5f5',
      minHeight: '100vh',
      padding: '24px',
    },
    header: {
      marginBottom: '24px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filterContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '16px',
      marginBottom: '24px',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    summaryCard: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      padding: '24px',
      marginBottom: '24px',
    },
    summaryItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '16px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
    },
    chartContainer: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      padding: '24px',
      marginBottom: '24px',
      height: '500px', // Increased height
    },
    smallChartContainer: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      padding: '24px',
      marginBottom: '24px',
      height: '400px',
    },
  };

  if (isDataLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box sx={styles.pageContainer}>
      {isDataLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <Box sx={styles.header}>
        <Typography variant="h4" fontWeight="bold" color="primary">
          Tickets Dashboard
        </Typography>
      </Box>

      <Box sx={styles.filterContainer}>
        <TextField
          fullWidth
          label="Search Tickets"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          select
          label="Route"
          value={selectedRoute}
          onChange={handleRouteChange}
          variant="outlined"
        >
          <MenuItem value="all">All Routes</MenuItem>
          {Object.values(collections).map((route) => (
            <MenuItem key={route} value={route}>
              {route}
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            localeText={{ start: 'Start Date', end: 'End Date' }}
            value={selectedDateRange}
            onChange={handleDateRangeChange}
          />
        </LocalizationProvider>

        <TextField
          fullWidth
          select
          label="Time Period"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="week">Weekly</MenuItem>
          <MenuItem value="month">Monthly</MenuItem>
          <MenuItem value="year">Yearly</MenuItem>
          <MenuItem value="specific">Custom Range</MenuItem>
        </TextField>
      </Box>

      <Grid container spacing={3} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} md={4}> {/* Adjusted md value to 4 for 3 items per row */}
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#e3f2fd', padding: '24px' }}> {/* Light Blue KPI Card, Adjusted padding */}
            <ConfirmationNumberIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#1976d2' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#1976d2' }}>{totalTickets}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Tickets</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}> {/* Adjusted md value to 4 for 3 items per row */}
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#f8bbd0', padding: '24px' }}> {/* Light Pink KPI Card, Adjusted padding */}
            <PeopleIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#c2185b' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#c2185b' }}>{totalSeatCapacity}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Passengers</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}> {/* Adjusted md value to 4 for 3 items per row */}
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#dcedc8', padding: '24px' }}> {/* Light Green KPI Card, Adjusted padding */}
            <TrainIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#558b2f' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#558b2f' }}>{totalkm}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Distance KM</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}> {/* Adjusted md value to 4 for 3 items per row */}
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#ffecb3', padding: '24px' }}> {/* Light Yellow KPI Card, Adjusted padding */}
            <TrainIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#f9a825' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#f9a825' }}>{totalStartStations}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Stations</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}> {/* Adjusted md value to 4 for 3 items per row */}
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#ffe0b2', padding: '24px' }}> {/* Light Orange KPI Card, Adjusted padding */}
            <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#ef6c00' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#ef6c00' }}>${totalCharge.toFixed(2)}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Charge</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}> {/* Adjusted md value to 4 for 3 items per row */}
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#f0f4c3', padding: '24px' }}> {/* Light Lime KPI Card, Adjusted padding */}
            <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#c0ca33' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#c0ca33' }}>${totalPrice.toFixed(2)}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Price</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            ...styles.summaryItem, 
            backgroundColor: '#e1bee7', // Light Purple background
            padding: '24px'
          }}>
            <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#6a1b9a' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#6a1b9a' }}>
              ${mainTotalCharge.toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>
              Main Total Charge
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            ...styles.summaryItem, 
            backgroundColor: '#b2dfdb', // Light Teal background
            padding: '24px'
          }}>
            <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#00695c' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#00695c' }}>
              ${(totalCharge - mainTotalCharge).toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>
              Additional Charge
            </Typography>
            <Typography variant="caption" sx={{ textAlign: 'center', mt: 1, color: '#00695c' }}>
              (Total Charge - Main Total Charge)
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={styles.chartContainer}>
            <Typography variant="h6" gutterBottom>
              Route Statistics
            </Typography>
            <Bar 
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                },
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.smallChartContainer}>
            <Typography variant="h6" gutterBottom>
              Ticket Distribution
            </Typography>
            <Pie 
              data={pieData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'right',
                  },
                },
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.smallChartContainer}>
            <Typography variant="h6" gutterBottom>
              Revenue by Route
            </Typography>
            <Bar 
              data={revenueData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                  legend: {
                    position: 'top',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Paper sx={{ mt: 3, mb: 3 }}>
        <DataGrid
          rows={filteredTickets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25]}
          checkboxSelection
          disableRowSelectionOnClick
          autoHeight
          getRowId={(row) => row.id}
          loading={isDataLoading}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: () => (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography>No tickets found</Typography>
              </Box>
            ),
          }}
          sx={{
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
        />
      </Paper>

      {selectedDateRange[0] && selectedDateRange[1] && (
        <RoutesSummaryTable 
          filteredTickets={filteredTickets}
          collections={collections}
        />
      )}
    </Box>
  );
};

// Add this custom loading overlay component
const CustomLoadingOverlay = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <CircularProgress />
    </Box>
  );
};

export default TicketsPage;