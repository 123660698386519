import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Card,
  CardContent,
  Pagination,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Alert,
  Tooltip,
  ButtonGroup,
} from "@mui/material";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import dayjs from "dayjs";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  FilterList as FilterListIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  DateRange as DateRangeIcon,
  FileDownload as FileDownloadIcon,
  Sort as SortIcon,
} from "@mui/icons-material";

const ActiveCarsPage = () => {
  const [plateOccurrences, setPlateOccurrences] = useState({});
  const [inactivePlates, setInactivePlates] = useState([]);
  const [inactiveDates, setInactiveDates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterPlate, setFilterPlate] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const [sortBy, setSortBy] = useState("count"); // New state for sorting: 'count' or 'route'
  const platesPerPage = 5;

  const collections = {
    tickets_getout: "Modjo",
    tickets_getout_Alemgena: "Alemgena",
    tickets_getout_sebeta: "Sebeta",
    tickets_getout_CheffeDonsa: "Cheffe-Donsa",
    tickets_getout_leman: "Lemmen",
    tickets_getout_welliso: "Welliso",
    tickets_getout_Tajji: "Tajji",
    tickets_getout_busa: "Busa",
    tickets_getout_sadeen: "Sadeen",
    tickets_getout_Sululta: "Sululta",
    tickets_getout_Burayu: "Burayu",
  };

  const generateDateRange = (start, end) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const dates = [];

    let currentDate = startDate;
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  };

  const transformPlateNumber = (plateNumber) => {
    const formattedNumber = plateNumber.replace(/^\d{0,3}/, "3");
    return formattedNumber.includes("-")
      ? formattedNumber
      : `3-${formattedNumber}`;
  };

  useEffect(() => {
    const fetchOccurrences = async () => {
      setIsLoading(true);
      let occurrences = {};
      let activeDatesMap = {};

      try {
        const [startDate, endDate] = dateRange;
        const rangeDates =
          startDate && endDate
            ? generateDateRange(startDate, endDate)
            : [dayjs().format("YYYY-MM-DD")];

        for (const [collectionName, routeName] of Object.entries(collections)) {
          if (selectedRoute && routeName !== selectedRoute) continue;

          for (const date of rangeDates) {
            const docRef = doc(db, collectionName, date);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const data = docSnap.data();
              Object.values(data).forEach((ticket) => {
                const plateNumber = ticket["Plate Number"];
                if (
                  plateNumber &&
                  (!filterPlate || plateNumber.includes(filterPlate))
                ) {
                  occurrences[plateNumber] = {
                    count: (occurrences[plateNumber]?.count || 0) + 1,
                    route: routeName,
                  };

                  activeDatesMap[plateNumber] = [
                    ...(activeDatesMap[plateNumber] || []),
                    date,
                  ];
                }
              });
            }
          }
        }

        setPlateOccurrences(occurrences);
        await fetchInactivePlates(
          Object.keys(occurrences),
          rangeDates,
          activeDatesMap
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOccurrences();
  }, [dateRange, filterPlate, selectedRoute]);

  const fetchInactivePlates = async (
    activePlates,
    rangeDates,
    activeDatesMap
  ) => {
    try {
      const routesRef = collection(db, "Routes");
      const routesSnapshot = await getDocs(routesRef);

      let allRoutePlates = [];
      routesSnapshot.forEach((doc) => {
        const data = doc.data();
        allRoutePlates = allRoutePlates.concat(data.plateNumbers || []);
      });

      const inactive = allRoutePlates.filter(
        (plate) => !activePlates.includes(plate)
      );

      let inactiveDateMap = {};
      inactive.forEach((plate) => {
        const inactiveDates = rangeDates.filter(
          (date) => !activeDatesMap[plate]?.includes(date)
        );
        inactiveDateMap[plate] = inactiveDates;
      });

      setInactivePlates(inactive);
      setInactiveDates(inactiveDateMap);
    } catch (error) {
      console.error("Error fetching inactive plates:", error);
      setError(error.message);
    }
  };

  // Sort active plates based on the selected sorting method
  const activePlates = Object.entries(plateOccurrences)
    .sort(([, a], [, b]) => {
      if (sortBy === "route") {
        return a.route.localeCompare(b.route); // Sort alphabetically by route
      } else {
        return b.count - a.count; // Default sort by count (descending)
      }
    })
    .slice((activePage - 1) * platesPerPage, activePage * platesPerPage);

  const inactivePlatesPaginated = inactivePlates.slice(
    (inactivePage - 1) * platesPerPage,
    inactivePage * platesPerPage
  );

  // Function to export data as CSV
  const exportToCSV = (data, filename) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    
    // Add headers
    if (filename.includes("active")) {
      csvContent += "Plate Number,Route,Occurrences\n";
      
      // Add data rows for active plates
      data.forEach(([plateNumber, { count, route }]) => {
        csvContent += `${transformPlateNumber(plateNumber)},${route},${count}\n`;
      });
    } else {
      csvContent += "Plate Number,Inactive Dates\n";
      
      // Add data rows for inactive plates
      data.forEach((plateNumber) => {
        csvContent += `${transformPlateNumber(plateNumber)},${inactiveDates[plateNumber]?.join("; ")}\n`;
      });
    }
    
    // Create download link and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Export all active plates (not just the paginated ones)
  const exportActivePlates = () => {
    const allActivePlates = Object.entries(plateOccurrences).sort(([, a], [, b]) => {
      if (sortBy === "route") {
        return a.route.localeCompare(b.route);
      } else {
        return b.count - a.count;
      }
    });
    
    exportToCSV(allActivePlates, `active_cars_${dayjs().format("YYYY-MM-DD")}`);
  };

  // Export all inactive plates
  const exportInactivePlates = () => {
    exportToCSV(inactivePlates, `inactive_cars_${dayjs().format("YYYY-MM-DD")}`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box p={3}>
        <Typography variant="h4" gutterBottom align="center" sx={{ color: '#1976d2' }}>
          Active Cars Management
        </Typography>

        {/* Filters Section */}
        <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ color: '#1976d2' }}>
              <FilterListIcon sx={{ mr: 1 }} /> Filters
            </Typography>
            <Box display="flex" gap={2} flexWrap="wrap" alignItems="center">
              <Tooltip title="Select date range" placement="top">
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={dateRange}
                  onChange={(newValue) => setDateRange(newValue)}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} />
                      <Box mx={2}>to</Box>
                      <TextField {...endProps} />
                    </>
                  )}
                />
              </Tooltip>
              <Tooltip title="Filter by plate number" placement="top">
                <TextField
                  label="Plate Number"
                  variant="outlined"
                  value={filterPlate}
                  onChange={(e) => setFilterPlate(e.target.value)}
                  sx={{ minWidth: 200 }}
                />
              </Tooltip>
              <Tooltip title="Filter by route" placement="top">
                <TextField
                  select
                  label="Route"
                  variant="outlined"
                  value={selectedRoute}
                  onChange={(e) => setSelectedRoute(e.target.value)}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem value="">All Routes</MenuItem>
                  {Object.values(collections).map((route) => (
                    <MenuItem key={route} value={route}>
                      {route}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
              <Tooltip title="Clear filters" placement="top">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ClearIcon />}
                  onClick={() => {
                    setDateRange([null, null]);
                    setFilterPlate("");
                    setSelectedRoute("");
                  }}
                >
                  Clear Filters
                </Button>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>

        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Active Plates Section */}
            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6">
                    Active Plate Numbers ({Object.keys(plateOccurrences).length})
                  </Typography>
                  <Box>
                    <ButtonGroup variant="outlined" sx={{ mr: 2 }}>
                      <Tooltip title="Sort by occurrences" placement="top">
                        <Button 
                          color={sortBy === "count" ? "primary" : "inherit"}
                          onClick={() => setSortBy("count")}
                          startIcon={<SortIcon />}
                        >
                          By Count
                        </Button>
                      </Tooltip>
                      <Tooltip title="Sort by route" placement="top">
                        <Button 
                          color={sortBy === "route" ? "primary" : "inherit"}
                          onClick={() => setSortBy("route")}
                          startIcon={<SortIcon />}
                        >
                          By Route
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    <Tooltip title="Export active cars to CSV" placement="top">
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<FileDownloadIcon />}
                        onClick={exportActivePlates}
                      >
                        Export CSV
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {dateRange[0] && dateRange[1]
                    ? `${Object.keys(plateOccurrences).length} Occurrences from ${dayjs(
                        dateRange[0]
                      ).format("YYYY-MM-DD")} to ${dayjs(dateRange[1]).format(
                        "YYYY-MM-DD"
                      )}`
                    : "Showing all dates."}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {activePlates.map(([plateNumber, { count, route }]) => (
                        <TableRow key={plateNumber}>
                          <TableCell>{transformPlateNumber(plateNumber)}</TableCell>
                          <TableCell>{route}</TableCell>
                          <TableCell>{count} Occurrences</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={Math.ceil(Object.keys(plateOccurrences).length / platesPerPage)}
                  page={activePage}
                  onChange={(_, value) => setActivePage(value)}
                  sx={{ mt: 2 }}
                  shape="rounded"
                />
              </CardContent>
            </Card>

            {/* Inactive Plates Section */}
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6">
                    Inactive Plate Numbers ({inactivePlates.length})
                  </Typography>
                  <Tooltip title="Export inactive cars to CSV" placement="top">
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<FileDownloadIcon />}
                      onClick={exportInactivePlates}
                    >
                      Export CSV
                    </Button>
                  </Tooltip>
                </Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {inactivePlatesPaginated.map((plateNumber) => (
                        <TableRow key={plateNumber}>
                          <TableCell>{transformPlateNumber(plateNumber)}</TableCell>
                          <TableCell>{inactiveDates[plateNumber]?.join(", ")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={Math.ceil(inactivePlates.length / platesPerPage)}
                  page={inactivePage}
                  onChange={(_, value) => setInactivePage(value)}
                  sx={{ mt: 2 }}
                  shape="rounded"
                />
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default ActiveCarsPage;
