// import React, { useEffect, useState, lazy, Suspense } from 'react';
// import { 
//   Box, 
//   Button, 
//   Container, 
//   Typography, 
//   Grid, 
//   Paper, 
//   CircularProgress, 
//   IconButton, 
//   useMediaQuery, 
//   TextField,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText
// } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
// import CountUp from 'react-countup';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import {
//   AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
// } from 'recharts';
// import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
// import SpeedIcon from '@mui/icons-material/Speed';
// import TimelineIcon from '@mui/icons-material/Timeline';
// import SavingsIcon from '@mui/icons-material/Savings';
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import GroupIcon from '@mui/icons-material/Group';
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import PlaceIcon from '@mui/icons-material/Place';
// import { useTheme } from '../context/ThemeContext';
// import ParticleBackground from '../components/ParticleBackground';
// import { collection, getDocs, onSnapshot } from 'firebase/firestore';
// import { db } from '../firebase';
// import { 
//   BarChart, 
//   Bar, 
//   PieChart,
//   Pie,
//   Cell,
//   LineChart,
//   Line,
//   Legend
// } from 'recharts';

// // Lazy load the Google Maps components
// const MapSection = lazy(() => import('./components/MapSection'));

// const MotionBox = React.memo(Box);

// // Define colors for the pie chart
// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// const LandingPage = () => {
//   const navigate = useNavigate();
//   const [selectedStation, setSelectedStation] = useState(null);
//   const { isDarkMode, toggleTheme, theme } = useTheme();
//   const isMobile = useMediaQuery('(max-width:600px)');
//   const [stats, setStats] = useState({
//     totalPassengers: 5000,
//     totalStations: 11,
//     activeVehicles: 150,
//     totalDestinations: 0,
//     lastPassengerCount: 5000,  // Track last passenger count
//     lastVehicleCount: 150      // Track last vehicle count
//   });
//   const [chartData, setChartData] = useState({
//     passengerTrend: [],
//     vehicleUtilization: [],
//     stationDistribution: []
//   });

//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       once: true,
//       offset: 100,
//       easing: 'ease-out-cubic'
//     });
//   }, []);

//   // Decorative background pattern
//   const backgroundPattern = {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     opacity: 0.1,
//     background: `
//       radial-gradient(circle at 0% 0%, ${theme.primary.main} 0%, transparent 50%),
//       radial-gradient(circle at 100% 0%, ${theme.secondary.main} 0%, transparent 50%),
//       radial-gradient(circle at 50% 100%, ${theme.primary.main} 0%, transparent 50%)
//     `,
//     pointerEvents: 'none',
//     zIndex: 0
//   };

//   // Glass effect styles
//   const glassEffect = {
//     background: theme.background.paper,
//     backdropFilter: 'blur(10px)',
//     border: `1px solid ${theme.background.paperHover}`,
//     transition: 'all 0.3s ease-in-out'
//   };

//   // Decorative line styles
//   const decorativeLine = {
//     position: 'absolute',
//     height: '100%',
//     width: '2px',
//     background: `linear-gradient(to bottom, transparent, ${theme.text.secondary}, transparent)`,
//     left: '50%',
//     transform: 'translateX(-50%)',
//     opacity: 0.1,
//     zIndex: 0
//   };

//   // Enhanced glass effect for cards
//   const enhancedGlassEffect = {
//     ...glassEffect,
//     position: 'relative',
//     '&:before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       borderRadius: 'inherit',
//       background: `linear-gradient(120deg, ${theme.background.paperHover} 0%, transparent 100%)`,
//       opacity: 0,
//       transition: 'opacity 0.3s ease-in-out'
//     },
//     '&:hover:before': {
//       opacity: 0.1
//     }
//   };

//   // Sample data for the fleet utilization chart
//   const fleetData = [
//     { name: '6AM', utilization: 45 },
//     { name: '9AM', utilization: 75 },
//     { name: '12PM', utilization: 85 },
//     { name: '3PM', utilization: 70 },
//     { name: '6PM', utilization: 90 },
//     { name: '9PM', utilization: 60 }
//   ];

//   const features = [
//     {
//       icon: <PrecisionManufacturingIcon sx={{ fontSize: 40 }} />,
//       title: 'Fleet Optimization',
//       description: 'AI-driven insights for optimal vehicle allocation and route planning'
//     },
//     {
//       icon: <SpeedIcon sx={{ fontSize: 40 }} />,
//       title: 'Real-time Monitoring',
//       description: 'Track vehicle status, maintenance needs, and performance metrics instantly'
//     },
//     {
//       icon: <TimelineIcon sx={{ fontSize: 40 }} />,
//       title: 'Performance Analytics',
//       description: 'Comprehensive analytics and reporting for data-driven decisions'
//     },
//     {
//       icon: <SavingsIcon sx={{ fontSize: 40 }} />,
//       title: 'Cost Management',
//       description: 'Optimize operational costs through predictive maintenance and efficient routing'
//     }
//   ];

//   const roleFeatures = [
//     {
//       title: 'Admin Access',
//       description: 'Full control over fleet management and system settings',
//       items: [
//         'Complete fleet oversight and management',
//         'User role administration',
//         'System configuration and customization',
//         'Advanced analytics and reporting'
//       ]
//     },
//     {
//       title: 'Guest Access',
//       description: 'View-only access to essential fleet information',
//       items: [
//         'Real-time fleet status monitoring',
//         'Basic performance metrics',
//         'Service station locations',
//         'Public reports and statistics'
//       ]
//     }
//   ];

//   const stations = [
//     { id: 1, name: 'Central Station', position: { lat: 9.0222, lng: 38.7468 }, capacity: 50, activeVehicles: 35 },
//     { id: 2, name: 'North Hub', position: { lat: 9.0322, lng: 38.7568 }, capacity: 40, activeVehicles: 28 },
//     { id: 3, name: 'South Center', position: { lat: 9.0122, lng: 38.7368 }, capacity: 45, activeVehicles: 30 },
//   ];

//   // Testimonials data
//   const testimonials = [
//     {
//       name: "Tewodros Bekele",
//       role: "Transport Director",
//       company: "Ethiopian Cargo Express",
//       content: "As a major logistics provider in Ethiopia, we needed a system that understands our local transport ecosystem. Smart Technologies' platform has improved our fleet efficiency across all regions, especially during peak seasons.",
//       image: "TB"
//     },
//     {
//       name: "Hirut Alemayehu",
//       role: "Operations Director",
//       company: "Addis Metropolitan Transit",
//       content: "The interface and local support team made implementation smooth for our drivers. Real-time tracking in Addis Ababa's busy streets has revolutionized our service delivery.",
//       image: "HA"
//     },
//     {
//       name: "Fekadu Tessema",
//       role: "General Manager",
//       company: "Oromia Transport Solutions",
//       content: "Smart Technologies understands Ethiopian transport challenges. Their system helped us manage our regional operations effectively, with excellent support across all regions.",
//       image: "FT"
//     }
//   ];

//   // Real-time data fetching
//   useEffect(() => {
//     // Subscribe to stations collection
//     const stationsUnsubscribe = onSnapshot(collection(db, 'Allroutes'), (snapshot) => {
//       let totalStations = 0;
//       let totalDestinations = 0;
      
//       snapshot.forEach(doc => {
//         totalStations++;
//         // Count destinations from routes
//         const routes = doc.data().routes || {};
//         Object.keys(routes).forEach(routeId => {
//           if (routeId !== 'exists') {
//             totalDestinations++;
//           }
//         });
//       });

//       setStats(prev => ({
//         ...prev,
//         totalStations,
//         totalDestinations
//       }));
//     });

//     // Simulate cumulative passenger count
//     const passengerInterval = setInterval(() => {
//       setStats(prev => {
//         const increment = Math.floor(Math.random() * 10) + 1;
//         return {
//           ...prev,
//           lastPassengerCount: prev.totalPassengers,
//           totalPassengers: prev.totalPassengers + increment
//         };
//       });
//     }, 3000);

//     // Simulate cumulative vehicle count
//     const vehicleInterval = setInterval(() => {
//       setStats(prev => {
//         const change = Math.random() > 0.5 ? 1 : -1;
//         const newCount = Math.max(0, prev.activeVehicles + change); // Prevent negative values
//         return {
//           ...prev,
//           lastVehicleCount: prev.activeVehicles,
//           activeVehicles: newCount
//         };
//       });
//     }, 5000);

//     // Generate chart data
//     const generateChartData = () => {
//       const passengerTrend = Array.from({ length: 24 }, (_, i) => ({
//         hour: `${i}:00`,
//         passengers: Math.floor(Math.random() * 100) + 50,
//         vehicles: Math.floor(Math.random() * 30) + 20
//       }));

//       const vehicleUtilization = [
//         { name: 'Active', value: stats.activeVehicles },
//         { name: 'Maintenance', value: Math.floor(stats.activeVehicles * 0.1) },
//         { name: 'Idle', value: Math.floor(stats.activeVehicles * 0.15) }
//       ];

//       const stationDistribution = [
//         { name: 'Level 1', value: Math.floor(stats.totalStations * 0.5) },
//         { name: 'Level 2', value: Math.floor(stats.totalStations * 0.3) },
//         { name: 'Level 3', value: Math.floor(stats.totalStations * 0.2) }
//       ];

//       setChartData({
//         passengerTrend,
//         vehicleUtilization,
//         stationDistribution
//       });
//     };

//     generateChartData();
//     const chartInterval = setInterval(generateChartData, 10000);

//     return () => {
//       stationsUnsubscribe();
//       clearInterval(passengerInterval);
//       clearInterval(vehicleInterval);
//       clearInterval(chartInterval);
//     };
//   }, [stats.activeVehicles, stats.totalStations]);

//   // Statistics Section with enhanced styling
//   const StatCard = ({ icon: Icon, value, label, color }) => (
//     <Paper sx={{ 
//       p: 4, 
//       textAlign: 'center',
//       background: `linear-gradient(135deg, ${theme.background.paper} 0%, ${color}15 100%)`,
//       borderRadius: '16px',
//       boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
//       backdropFilter: 'blur(10px)',
//       border: '1px solid rgba(255, 255, 255, 0.18)',
//       transform: 'translateY(0)',
//       transition: 'all 0.3s ease-in-out',
//       '&:hover': {
//         transform: 'translateY(-10px)',
//         boxShadow: `0 12px 40px 0 ${color}30`
//       }
//     }}>
//       <Box sx={{ 
//         color: color,
//         fontSize: 48,
//         mb: 2,
//         transform: 'scale(1)',
//         transition: 'transform 0.3s ease-in-out',
//         '&:hover': {
//           transform: 'scale(1.1)'
//         }
//       }}>
//         <Icon />
//       </Box>
//       <Typography 
//         variant="h3" 
//         sx={{ 
//           color: theme.text.primary,
//           fontSize: { xs: '2.5rem', md: '3rem' },
//           fontWeight: 700,
//           textShadow: `0 2px 10px ${color}40`
//         }}
//       >
//         <CountUp 
//           start={stats[`last${label.replace(/\s+/g, '')}Count`] || 0}
//           end={value} 
//           duration={2.5} 
//           separator="," 
//           preserveValue={true}
//         />
//       </Typography>
//       <Typography 
//         variant="h6" 
//         sx={{ 
//           color: theme.text.secondary,
//           mt: 1,
//           fontSize: { xs: '1.1rem', md: '1.25rem' },
//           fontWeight: 500
//         }}
//       >
//         {label}
//       </Typography>
//     </Paper>
//   );

//   // Replace the Statistics Grid with enhanced version
//   const StatisticsSection = () => (
//     <Grid container spacing={4} sx={{ py: 8 }} data-aos="fade-up">
//       <Grid item xs={12} md={3}>
//         <StatCard 
//           icon={GroupIcon} 
//           value={stats.totalPassengers} 
//           label="Active Passengers"
//           color="#4CAF50"
//         />
//       </Grid>
//       <Grid item xs={12} md={3}>
//         <StatCard 
//           icon={LocationOnIcon} 
//           value={stats.totalStations} 
//           label="Service Stations"
//           color="#2196F3"
//         />
//       </Grid>
//       <Grid item xs={12} md={3}>
//         <StatCard 
//           icon={DirectionsCarIcon} 
//           value={stats.activeVehicles} 
//           label="Active Vehicles"
//           color="#FF9800"
//         />
//       </Grid>
//       <Grid item xs={12} md={3}>
//         <StatCard 
//           icon={PlaceIcon} 
//           value={stats.totalDestinations} 
//           label="Total Destinations"
//           color="#9C27B0"
//         />
//       </Grid>
//     </Grid>
//   );

//   return (
//     <Box sx={{ 
//       minHeight: '100vh',
//       background: theme.background.default,
//       position: 'relative',
//       overflow: 'hidden'
//     }}>
//       <ParticleBackground />
      
//       {/* Decorative Background */}
//       <Box sx={backgroundPattern} />
      
//       {/* Decorative Lines */}
//       <Box sx={{ ...decorativeLine, left: '10%' }} />
//       <Box sx={{ ...decorativeLine, left: '90%' }} />

//       {/* Theme Toggle */}
//       <Box sx={{ position: 'fixed', top: 20, right: 20, zIndex: 10 }}>
//         <IconButton 
//           onClick={toggleTheme}
//           sx={{ 
//             ...glassEffect,
//             color: theme.text.primary,
//             '&:hover': {
//               background: theme.background.paperHover,
//               transform: 'scale(1.1)'
//             }
//           }}
//         >
//           {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
//         </IconButton>
//       </Box>

//       <Container maxWidth="xl" sx={{ py: 4, position: 'relative', zIndex: 1 }}>
//         {/* Hero Section */}
//         <MotionBox 
//           sx={{ 
//             textAlign: 'center',
//             py: { xs: 8, md: 12 },
//             position: 'relative'
//           }}
//           data-aos="fade-up"
//         >
//           <Typography 
//             variant="h2" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 2,
//               fontSize: { xs: '2.5rem', md: '3.5rem' },
//               fontWeight: 700,
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)',
//               position: 'relative',
//               '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: -10,
//                 left: '50%',
//                 transform: 'translateX(-50%)',
//                 width: 100,
//                 height: 4,
//                 background: theme.text.secondary,
//                 borderRadius: 2
//               }
//             }}
//           >
//             Smart Technologies
//             <Typography 
//               component="span" 
//               sx={{ 
//                 display: 'block',
//                 fontSize: { xs: '2rem', md: '2.5rem' },
//                 color: theme.text.secondary,
//                 mt: 1
//               }}
//             >
//               Intelligent Vehicle Management System
//             </Typography>
//           </Typography>
//           <Typography 
//             variant="h5" 
//             sx={{ 
//               color: theme.text.secondary,
//               mb: 6,
//               fontSize: { xs: '1.5rem', md: '2rem' },
//               maxWidth: '800px',
//               mx: 'auto',
//               opacity: 0.9
//             }}
//           >
//             Modern Transportation Solutions for Ethiopia
//             <Typography 
//               component="span" 
//               sx={{ 
//                 display: 'block',
//                 fontSize: '90%',
//                 mt: 1,
//                 opacity: 0.8
//               }}
//             >
//               AI-driven Fleet Management and Optimization
//             </Typography>
//           </Typography>
//           <Button 
//             variant="contained"
//             size="large"
//             onClick={() => navigate('/login')}
//             sx={{
//               ...enhancedGlassEffect,
//               px: { xs: 4, md: 6 },
//               py: { xs: 1.5, md: 2 },
//               fontSize: { xs: '1rem', md: '1.2rem' },
//               color: theme.text.primary,
//               '&:hover': {
//                 transform: 'scale(1.05)',
//                 boxShadow: `0 0 20px ${theme.text.secondary}40`
//               }
//             }}
//           >
//             Get Started
//           </Button>
//         </MotionBox>

//         {/* Replace old statistics section with new one */}
//         <StatisticsSection />

//         {/* Real-time Analytics Section */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 6, 
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)',
//               position: 'relative',
//               '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: -15,
//                 left: '50%',
//                 transform: 'translateX(-50%)',
//                 width: 60,
//                 height: 4,
//                 background: `linear-gradient(90deg, ${theme.text.secondary}, ${theme.primary.main})`,
//                 borderRadius: 2
//               }
//             }}
//           >
//             Live Analytics
//           </Typography>
//           <Grid container spacing={4}>
//             <Grid item xs={12} md={8}>
//               <Paper sx={{ 
//                 p: 4,
//                 ...glassEffect,
//                 height: 400
//               }}>
//                 <Typography variant="h6" gutterBottom sx={{ color: theme.text.primary }}>
//                   Passenger Trend (24 Hours)
//                 </Typography>
//                 <ResponsiveContainer width="100%" height="100%">
//                   <AreaChart data={chartData.passengerTrend}>
//                     <defs>
//                       <linearGradient id="colorPassengers" x1="0" y1="0" x2="0" y2="1">
//                         <stop offset="5%" stopColor={theme.text.secondary} stopOpacity={0.8}/>
//                         <stop offset="95%" stopColor={theme.text.secondary} stopOpacity={0}/>
//                       </linearGradient>
//                     </defs>
//                     <CartesianGrid strokeDasharray="3 3" stroke={theme.text.hint} />
//                     <XAxis dataKey="hour" stroke={theme.text.secondary} />
//                     <YAxis stroke={theme.text.secondary} />
//                     <Tooltip 
//                       contentStyle={{ 
//                         background: theme.background.paper,
//                         border: 'none',
//                         borderRadius: '4px',
//                         color: theme.text.primary
//                       }}
//                     />
//                     <Area 
//                       type="monotone" 
//                       dataKey="passengers" 
//                       stroke={theme.text.secondary}
//                       fillOpacity={1}
//                       fill="url(#colorPassengers)"
//                     />
//                   </AreaChart>
//                 </ResponsiveContainer>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <Paper sx={{ 
//                 p: 4,
//                 ...glassEffect,
//                 height: 400
//               }}>
//                 <Typography variant="h6" gutterBottom sx={{ color: theme.text.primary }}>
//                   Vehicle Status
//                 </Typography>
//                 <ResponsiveContainer width="100%" height="100%">
//                   <PieChart>
//                     <Pie
//                       data={chartData.vehicleUtilization}
//                       cx="50%"
//                       cy="50%"
//                       labelLine={false}
//                       outerRadius={100}
//                       fill="#8884d8"
//                       dataKey="value"
//                     >
//                       {chartData.vehicleUtilization.map((entry, index) => (
//                         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                       ))}
//                     </Pie>
//                     <Legend />
//                     <Tooltip 
//                       contentStyle={{ 
//                         background: theme.background.paper,
//                         border: 'none',
//                         borderRadius: '4px',
//                         color: theme.text.primary
//                       }}
//                     />
//                   </PieChart>
//                 </ResponsiveContainer>
//               </Paper>
//             </Grid>
//             <Grid item xs={12}>
//               <Paper sx={{ 
//                 p: 4,
//                 ...glassEffect,
//                 height: 400
//               }}>
//                 <Typography variant="h6" gutterBottom sx={{ color: theme.text.primary }}>
//                   Station Distribution by Level
//                 </Typography>
//                 <ResponsiveContainer width="100%" height="100%">
//                   <BarChart data={chartData.stationDistribution}>
//                     <CartesianGrid strokeDasharray="3 3" stroke={theme.text.hint} />
//                     <XAxis dataKey="name" stroke={theme.text.secondary} />
//                     <YAxis stroke={theme.text.secondary} />
//                     <Tooltip 
//                       contentStyle={{ 
//                         background: theme.background.paper,
//                         border: 'none',
//                         borderRadius: '4px',
//                         color: theme.text.primary
//                       }}
//                     />
//                     <Bar dataKey="value" fill={theme.text.secondary} />
//                   </BarChart>
//                 </ResponsiveContainer>
//               </Paper>
//             </Grid>
//           </Grid>
//         </Box>

//         {/* Map Section */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 4, 
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)'
//             }}
//           >
//             Our Service Network
//           </Typography>
//           <Paper sx={{ 
//             p: 2,
//             ...glassEffect,
//             '&:hover': {
//               background: theme.background.paperHover
//             }
//           }}>
//             <Suspense fallback={
//               <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
//                 <CircularProgress sx={{ color: theme.text.secondary }} />
//               </Box>
//             }>
//               <MapSection 
//                 stations={stations}
//                 selectedStation={selectedStation}
//                 onStationSelect={setSelectedStation}
//                 isDarkMode={isDarkMode}
//               />
//             </Suspense>
//           </Paper>
//         </Box>

//         {/* Fleet Utilization Chart */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 4, 
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)'
//             }}
//           >
//             Live Fleet Performance
//           </Typography>
//           <Paper sx={{ 
//             p: 4,
//             ...glassEffect,
//             '&:hover': {
//               background: theme.background.paperHover
//             }
//           }}>
//             <ResponsiveContainer width="100%" height={400}>
//               <AreaChart data={fleetData}>
//                 <defs>
//                   <linearGradient id="colorUtilization" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor={theme.text.secondary} stopOpacity={0.8}/>
//                     <stop offset="95%" stopColor={theme.text.secondary} stopOpacity={0}/>
//                   </linearGradient>
//                 </defs>
//                 <CartesianGrid strokeDasharray="3 3" stroke={theme.text.hint} />
//                 <XAxis dataKey="name" stroke={theme.text.secondary} />
//                 <YAxis stroke={theme.text.secondary} />
//                 <Tooltip 
//                   contentStyle={{ 
//                     background: theme.background.paper,
//                     border: 'none',
//                     borderRadius: '4px',
//                     color: theme.text.primary
//                   }}
//                 />
//                 <Area 
//                   type="monotone" 
//                   dataKey="utilization" 
//                   stroke={theme.text.secondary}
//                   fillOpacity={1}
//                   fill="url(#colorUtilization)"
//                 />
//               </AreaChart>
//             </ResponsiveContainer>
//           </Paper>
//         </Box>

//         {/* Features Grid */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 6, 
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)',
//               position: 'relative',
//               '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: -15,
//                 left: '50%',
//                 transform: 'translateX(-50%)',
//                 width: 60,
//                 height: 4,
//                 background: theme.text.secondary,
//                 borderRadius: 2
//               }
//             }}
//           >
//             Key Features
//           </Typography>
//           <Grid container spacing={4}>
//             {features.map((feature, index) => (
//               <Grid item xs={12} sm={6} md={3} key={index}>
//                 <Paper sx={{ 
//                   p: 4, 
//                   height: '100%',
//                   ...enhancedGlassEffect,
//                   transform: 'translateY(0)',
//                   '&:hover': {
//                     transform: 'translateY(-8px)',
//                     boxShadow: `0 8px 16px ${theme.text.secondary}20`
//                   }
//                 }}>
//                   <Box sx={{ 
//                     color: theme.text.secondary,
//                     mb: 2,
//                     transform: 'scale(1)',
//                     transition: 'transform 0.3s ease-in-out',
//                     '&:hover': {
//                       transform: 'scale(1.1)'
//                     }
//                   }}>
//                     {feature.icon}
//                   </Box>
//                   <Typography 
//                     variant="h6" 
//                     sx={{ 
//                       color: theme.text.primary,
//                       mb: 2,
//                       fontSize: { xs: '1.1rem', md: '1.25rem' },
//                       fontWeight: 600
//                     }}
//                   >
//                     {feature.title}
//                   </Typography>
//                   <Typography 
//                     sx={{ 
//                       color: theme.text.secondary,
//                       lineHeight: 1.6
//                     }}
//                   >
//                     {feature.description}
//                   </Typography>
//                 </Paper>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>

//         {/* Role Features Section */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 6, 
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)'
//             }}
//           >
//             Tailored Access Levels
//           </Typography>
//           <Grid container spacing={4}>
//             {roleFeatures.map((role, index) => (
//               <Grid item xs={12} md={6} key={index}>
//                 <Paper sx={{ 
//                   p: 4,
//                   height: '100%',
//                   ...glassEffect,
//                   '&:hover': {
//                     background: theme.background.paperHover
//                   }
//                 }}>
//                   <Typography variant="h5" sx={{ color: theme.text.secondary, mb: 2 }}>
//                     {role.title}
//                   </Typography>
//                   <Typography sx={{ mb: 3, color: theme.text.hint }}>
//                     {role.description}
//                   </Typography>
//                   {role.items.map((item, i) => (
//                     <Typography 
//                       key={i} 
//                       sx={{ 
//                         mb: 1,
//                         display: 'flex',
//                         alignItems: 'center',
//                         color: theme.text.primary,
//                         '&:before': {
//                           content: '"•"',
//                           marginRight: '8px',
//                           color: theme.text.secondary
//                         }
//                       }}
//                     >
//                       {item}
//                     </Typography>
//                   ))}
//                 </Paper>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>

//         {/* Testimonials Section */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 6,
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)',
//               position: 'relative',
//               '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: -15,
//                 left: '50%',
//                 transform: 'translateX(-50%)',
//                 width: 60,
//                 height: 4,
//                 background: `linear-gradient(90deg, ${theme.text.secondary}, ${theme.primary.main})`,
//                 borderRadius: 2
//               }
//             }}
//           >
//             What Our Clients Say
//           </Typography>
//           <Grid container spacing={4}>
//             {[
//               {
//                 name: "Tewodros Bekele",
//                 role: "Transport Director",
//                 company: "Ethiopian Cargo Express",
//                 content: "As a major logistics provider in Ethiopia, we needed a system that understands our local transport ecosystem. Smart Technologies' platform has improved our fleet efficiency across all regions, especially during peak seasons.",
//                 image: "TB"
//               },
//               {
//                 name: "Hirut Alemayehu",
//                 role: "Operations Director",
//                 company: "Addis Metropolitan Transit",
//                 content: "The interface and local support team made implementation smooth for our drivers. Real-time tracking in Addis Ababa's busy streets has revolutionized our service delivery.",
//                 image: "HA"
//               },
//               {
//                 name: "Fekadu Tessema",
//                 role: "General Manager",
//                 company: "Oromia Transport Solutions",
//                 content: "Smart Technologies understands Ethiopian transport challenges. Their system helped us manage our regional operations effectively, with excellent support across all regions.",
//                 image: "FT"
//               }
//             ].map((testimonial, index) => (
//               <Grid item xs={12} md={4} key={index} data-aos="fade-up" data-aos-delay={index * 100}>
//                 <Paper sx={{ 
//                   p: 4,
//                   height: '100%',
//                   ...enhancedGlassEffect,
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   textAlign: 'center',
//                   position: 'relative',
//                   overflow: 'hidden'
//                 }}>
//                   <Box 
//                     sx={{
//                       width: 80,
//                       height: 80,
//                       borderRadius: '50%',
//                       mb: 3,
//                       display: 'flex',
//                       alignItems: 'center',
//                       justifyContent: 'center',
//                       background: theme.primary.main,
//                       color: theme.text.primary,
//                       fontSize: '2rem',
//                       fontWeight: 'bold',
//                       boxShadow: `0 4px 8px ${theme.text.secondary}20`,
//                       transition: 'all 0.3s ease-in-out',
//                       '&:hover': {
//                         transform: 'scale(1.1)',
//                         boxShadow: `0 8px 16px ${theme.text.secondary}40`
//                       }
//                     }}
//                   >
//                     {testimonial.image}
//                   </Box>
//                   <Typography sx={{ 
//                     color: theme.text.secondary,
//                     fontStyle: 'italic',
//                     mb: 3,
//                     lineHeight: 1.8,
//                     position: 'relative',
//                     zIndex: 1
//                   }}>
//                     "{testimonial.content}"
//                   </Typography>
//                   <Box sx={{ mt: 'auto' }}>
//                     <Typography 
//                       variant="h6" 
//                       sx={{ 
//                         color: theme.text.primary,
//                         mb: 1,
//                         fontWeight: 600
//                       }}
//                     >
//                       {testimonial.name}
//                     </Typography>
//                     <Typography sx={{ 
//                       color: theme.text.secondary,
//                       fontWeight: 500,
//                       mb: 1
//                     }}>
//                       {testimonial.role}
//                     </Typography>
//                     <Typography sx={{ 
//                       color: theme.accent || theme.text.secondary,
//                       fontSize: '0.9rem'
//                     }}>
//                       {testimonial.company}
//                     </Typography>
//                   </Box>
//                 </Paper>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>

//         {/* Contact Us Section */}
//         <Box sx={{ py: 8 }} data-aos="fade-up">
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 6,
//               textAlign: 'center',
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)'
//             }}
//           >
//             Contact Us
//           </Typography>
//           <Grid container spacing={4}>
//             <Grid item xs={12} md={6}>
//               <Paper sx={{ 
//                 p: 4,
//                 height: '100%',
//                 ...enhancedGlassEffect
//               }}>
//                 <Typography variant="h5" sx={{ color: theme.text.primary, mb: 3 }}>
//                   Get in Touch
//                 </Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <TextField
//                       fullWidth
//                       label="Name"
//                       variant="outlined"
//                       sx={{
//                         '& .MuiOutlinedInput-root': {
//                           '& fieldset': {
//                             borderColor: theme.text.secondary
//                           },
//                           '&:hover fieldset': {
//                             borderColor: theme.text.primary
//                           }
//                         },
//                         '& .MuiInputLabel-root': {
//                           color: theme.text.secondary
//                         }
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       fullWidth
//                       label="Email"
//                       variant="outlined"
//                       type="email"
//                       sx={{
//                         '& .MuiOutlinedInput-root': {
//                           '& fieldset': {
//                             borderColor: theme.text.secondary
//                           },
//                           '&:hover fieldset': {
//                             borderColor: theme.text.primary
//                           }
//                         },
//                         '& .MuiInputLabel-root': {
//                           color: theme.text.secondary
//                         }
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       fullWidth
//                       label="Message"
//                       variant="outlined"
//                       multiline
//                       rows={4}
//                       sx={{
//                         '& .MuiOutlinedInput-root': {
//                           '& fieldset': {
//                             borderColor: theme.text.secondary
//                           },
//                           '&:hover fieldset': {
//                             borderColor: theme.text.primary
//                           }
//                         },
//                         '& .MuiInputLabel-root': {
//                           color: theme.text.secondary
//                         }
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Button 
//                       variant="contained"
//                       fullWidth
//                       sx={{
//                         ...enhancedGlassEffect,
//                         py: 1.5,
//                         color: theme.text.primary,
//                         fontSize: '1.1rem'
//                       }}
//                     >
//                       Send Message
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Paper sx={{ 
//                 p: 4,
//                 height: '100%',
//                 ...enhancedGlassEffect
//               }}>
//                 <Typography variant="h5" sx={{ color: theme.text.primary, mb: 3 }}>
//                   Contact Information
//                 </Typography>
//                 <Box sx={{ mb: 3 }}>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                     <LocationOnOutlinedIcon sx={{ color: theme.text.secondary, mr: 2 }} />
//                     <Typography sx={{ color: theme.text.primary }}>
//                       Adama, Ethiopia
//                     </Typography>
//                   </Box>
//                   <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                     <EmailIcon sx={{ color: theme.text.secondary, mr: 2 }} />
//                     <Typography sx={{ color: theme.text.primary }}>
//                       info@smarttech.et
//                     </Typography>
//                   </Box>
//                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                     <PhoneIcon sx={{ color: theme.text.secondary, mr: 2 }} />
//                     <Typography sx={{ color: theme.text.primary }}>
//                       +251 939540127<br />
//                       +251 910960528<br />
//                       +251 913429438
//                     </Typography>
//                   </Box>
//                 </Box>
//                 <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
//                   Business Hours
//                 </Typography>
//                 <Typography sx={{ color: theme.text.secondary, mb: 1 }}>
//                   Monday - Friday: 8:30 AM - 5:30 PM
//                 </Typography>
//                 <Typography sx={{ color: theme.text.secondary }}>
//                   Saturday: 9:00 AM - 1:00 PM
//                 </Typography>
//               </Paper>
//             </Grid>
//           </Grid>
//         </Box>

//         {/* CTA Section */}
//         <Box 
//           sx={{ 
//             py: 12, 
//             textAlign: 'center',
//             position: 'relative',
//             '&:before': {
//               content: '""',
//               position: 'absolute',
//               top: 0,
//               left: '50%',
//               transform: 'translateX(-50%)',
//               width: '80%',
//               height: 1,
//               background: `linear-gradient(90deg, transparent, ${theme.text.secondary}40, transparent)`
//             }
//           }} 
//           data-aos="fade-up"
//         >
//           <Typography 
//             variant="h3" 
//             sx={{ 
//               color: theme.text.primary,
//               mb: 4,
//               fontSize: { xs: '2rem', md: '2.5rem' },
//               textShadow: '0 2px 4px rgba(0,0,0,0.2)'
//             }}
//           >
//             Ready to Get Started?
//           </Typography>
//           <Button 
//             variant="contained"
//             size="large"
//             onClick={() => navigate('/login')}
//             sx={{
//               ...enhancedGlassEffect,
//               px: { xs: 4, md: 6 },
//               py: { xs: 1.5, md: 2 },
//               fontSize: { xs: '1rem', md: '1.2rem' },
//               color: theme.text.primary,
//               '&:hover': {
//                 transform: 'scale(1.05)',
//                 boxShadow: `0 0 20px ${theme.text.secondary}40`
//               }
//             }}
//           >
//             Sign Up Now
//           </Button>
//         </Box>

//         {/* Footer */}
//         <Box 
//           component="footer" 
//           sx={{ 
//             py: 6,
//             mt: 8,
//             borderTop: `1px solid ${theme.text.secondary}40`,
//             position: 'relative'
//           }}
//         >
//           <Grid container spacing={4}>
//             <Grid item xs={12} md={4}>
//               <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
//                 Smart Technologies
//               </Typography>
//               <Typography sx={{ color: theme.text.secondary, mb: 2 }}>
//                 Leading the future of intelligent vehicle management with cutting-edge technology and innovation.
//               </Typography>
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
//                 Quick Links
//               </Typography>
//               <List>
//                 <ListItem disablePadding>
//                   <ListItemButton 
//                     component={Link} 
//                     to="/about"
//                     sx={{
//                       color: theme.text.secondary,
//                       '&:hover': {
//                         color: theme.text.primary,
//                         backgroundColor: 'transparent',
//                         transform: 'translateX(5px)'
//                       },
//                       transition: 'all 0.3s ease'
//                     }}
//                   >
//                     <ListItemIcon>
//                       <InfoOutlinedIcon sx={{ color: 'inherit' }} />
//                     </ListItemIcon>
//                     <ListItemText primary="About Us" />
//                   </ListItemButton>
//                 </ListItem>
//                 <ListItem disablePadding>
//                   <ListItemButton 
//                     component={Link} 
//                     to="/services"
//                     sx={{
//                       color: theme.text.secondary,
//                       '&:hover': {
//                         color: theme.text.primary,
//                         backgroundColor: 'transparent',
//                         transform: 'translateX(5px)'
//                       },
//                       transition: 'all 0.3s ease'
//                     }}
//                   >
//                     <ListItemIcon>
//                       <LocalGasStationIcon sx={{ color: 'inherit' }} />
//                     </ListItemIcon>
//                     <ListItemText primary="Services" />
//                   </ListItemButton>
//                 </ListItem>
//                 <ListItem disablePadding>
//                   <ListItemButton 
//                     component={Link} 
//                     to="/contact"
//                     sx={{
//                       color: theme.text.secondary,
//                       '&:hover': {
//                         color: theme.text.primary,
//                         backgroundColor: 'transparent',
//                         transform: 'translateX(5px)'
//                       },
//                       transition: 'all 0.3s ease'
//                     }}
//                   >
//                     <ListItemIcon>
//                       <ContactSupportIcon sx={{ color: 'inherit' }} />
//                     </ListItemIcon>
//                     <ListItemText primary="Contact" />
//                   </ListItemButton>
//                 </ListItem>
//               </List>
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
//                 Connect With Us
//               </Typography>
//               <Box sx={{ display: 'flex', gap: 2 }}>
//                 <IconButton 
//                   component="a"
//                   href="https://www.facebook.com"
//                   target="_blank"
//                   sx={{ 
//                     color: theme.text.secondary,
//                     '&:hover': { 
//                       color: '#1877F2',
//                       transform: 'translateY(-2px)'
//                     }
//                   }}
//                 >
//                   <FacebookIcon />
//                 </IconButton>
//                 <IconButton 
//                   component="a"
//                   href="https://www.twitter.com"
//                   target="_blank"
//                   sx={{ 
//                     color: theme.text.secondary,
//                     '&:hover': { 
//                       color: '#1DA1F2',
//                       transform: 'translateY(-2px)'
//                     }
//                   }}
//                 >
//                   <TwitterIcon />
//                 </IconButton>
//                 <IconButton 
//                   component="a"
//                   href="https://www.linkedin.com"
//                   target="_blank"
//                   sx={{ 
//                     color: theme.text.secondary,
//                     '&:hover': { 
//                       color: '#0A66C2',
//                       transform: 'translateY(-2px)'
//                     }
//                   }}
//                 >
//                   <LinkedInIcon />
//                 </IconButton>
//                 <IconButton 
//                   component="a"
//                   href="https://www.instagram.com"
//                   target="_blank"
//                   sx={{ 
//                     color: theme.text.secondary,
//                     '&:hover': { 
//                       color: '#E4405F',
//                       transform: 'translateY(-2px)'
//                     }
//                   }}
//                 >
//                   <InstagramIcon />
//                 </IconButton>
//               </Box>
//               <Typography 
//                 sx={{ 
//                   color: theme.text.secondary,
//                   textAlign: 'center',
//                   mt: 4,
//                   fontSize: '0.9rem'
//                 }}
//               >
//                 &copy; {new Date().getFullYear()} Smart Technologies Company. All rights reserved.
//               </Typography>
//             </Grid>
//           </Grid>
//         </Box>
//       </Container>
//     </Box>
//   );
// };

// export default LandingPage;
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  CircularProgress, 
  IconButton, 
  useMediaQuery, 
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SpeedIcon from '@mui/icons-material/Speed';
import TimelineIcon from '@mui/icons-material/Timeline';
import SavingsIcon from '@mui/icons-material/Savings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PlaceIcon from '@mui/icons-material/Place';
import { useTheme } from '../context/ThemeContext';
import ParticleBackground from '../components/ParticleBackground';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  BarChart, 
  Bar, 
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend
} from 'recharts';

// Lazy load the Google Maps components
const MapSection = lazy(() => import('./components/MapSection'));

const MotionBox = React.memo(Box);

// Define colors for the pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const LandingPage = () => {
  const navigate = useNavigate();
  const [selectedStation, setSelectedStation] = useState(null);
  const { isDarkMode, toggleTheme, theme } = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [stats, setStats] = useState({
    totalPassengers: 5000,
    totalStations: 11,
    activeVehicles: 150,
    totalDestinations: 0,
    lastPassengerCount: 5000,  // Track last passenger count
    lastVehicleCount: 150      // Track last vehicle count
  });
  const [chartData, setChartData] = useState({
    passengerTrend: [],
    vehicleUtilization: [],
    stationDistribution: []
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100,
      easing: 'ease-out-cubic'
    });
  }, []);

  // Decorative background pattern
  const backgroundPattern = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.1,
    background: `
      radial-gradient(circle at 0% 0%, ${theme.primary.main} 0%, transparent 50%),
      radial-gradient(circle at 100% 0%, ${theme.secondary.main} 0%, transparent 50%),
      radial-gradient(circle at 50% 100%, ${theme.primary.main} 0%, transparent 50%)
    `,
    pointerEvents: 'none',
    zIndex: 0
  };

  // Glass effect styles
  const glassEffect = {
    background: theme.background.paper,
    backdropFilter: 'blur(10px)',
    border: `1px solid ${theme.background.paperHover}`,
    transition: 'all 0.3s ease-in-out'
  };

  // Decorative line styles
  const decorativeLine = {
    position: 'absolute',
    height: '100%',
    width: '2px',
    background: `linear-gradient(to bottom, transparent, ${theme.text.secondary}, transparent)`,
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 0.1,
    zIndex: 0
  };

  // Enhanced glass effect for cards
  const enhancedGlassEffect = {
    ...glassEffect,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 'inherit',
      background: `linear-gradient(120deg, ${theme.background.paperHover} 0%, transparent 100%)`,
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out'
    },
    '&:hover:before': {
      opacity: 0.1
    }
  };

  // Sample data for the fleet utilization chart
  const fleetData = [
    { name: '6AM', utilization: 45 },
    { name: '9AM', utilization: 75 },
    { name: '12PM', utilization: 85 },
    { name: '3PM', utilization: 70 },
    { name: '6PM', utilization: 90 },
    { name: '9PM', utilization: 60 }
  ];

  const features = [
    {
      icon: <PrecisionManufacturingIcon sx={{ fontSize: 40 }} />,
      title: 'Fleet Optimization',
      description: 'AI-driven insights for optimal vehicle allocation and route planning'
    },
    {
      icon: <SpeedIcon sx={{ fontSize: 40 }} />,
      title: 'Real-time Monitoring',
      description: 'Track vehicle status, maintenance needs, and performance metrics instantly'
    },
    {
      icon: <TimelineIcon sx={{ fontSize: 40 }} />,
      title: 'Performance Analytics',
      description: 'Comprehensive analytics and reporting for data-driven decisions'
    },
    {
      icon: <SavingsIcon sx={{ fontSize: 40 }} />,
      title: 'Cost Management',
      description: 'Optimize operational costs through predictive maintenance and efficient routing'
    }
  ];

  const roleFeatures = [
    {
      title: 'Admin Access',
      description: 'Full control over fleet management and system settings',
      items: [
        'Complete fleet oversight and management',
        'User role administration',
        'System configuration and customization',
        'Advanced analytics and reporting'
      ]
    },
    {
      title: 'Guest Access',
      description: 'View-only access to essential fleet information',
      items: [
        'Real-time fleet status monitoring',
        'Basic performance metrics',
        'Service station locations',
        'Public reports and statistics'
      ]
    }
  ];

  const stations = [
    { id: 1, name: 'Central Station', position: { lat: 9.0222, lng: 38.7468 }, capacity: 50, activeVehicles: 35 },
    { id: 2, name: 'North Hub', position: { lat: 9.0322, lng: 38.7568 }, capacity: 40, activeVehicles: 28 },
    { id: 3, name: 'South Center', position: { lat: 9.0122, lng: 38.7368 }, capacity: 45, activeVehicles: 30 },
  ];

  // Testimonials data
  const testimonials = [
    {
      name: "Tewodros Bekele",
      role: "Transport Director",
      company: "Ethiopian Cargo Express",
      content: "As a major logistics provider in Ethiopia, we needed a system that understands our local transport ecosystem. Smart Technologies' platform has improved our fleet efficiency across all regions, especially during peak seasons.",
      image: "TB"
    },
    {
      name: "Hirut Alemayehu",
      role: "Operations Director",
      company: "Addis Metropolitan Transit",
      content: "The interface and local support team made implementation smooth for our drivers. Real-time tracking in Addis Ababa's busy streets has revolutionized our service delivery.",
      image: "HA"
    },
    {
      name: "Fekadu Tessema",
      role: "General Manager",
      company: "Oromia Transport Solutions",
      content: "Smart Technologies understands Ethiopian transport challenges. Their system helped us manage our regional operations effectively, with excellent support across all regions.",
      image: "FT"
    }
  ];

  // Real-time data fetching
  useEffect(() => {
    // Subscribe to stations collection
    const stationsUnsubscribe = onSnapshot(collection(db, 'Allroutes'), (snapshot) => {
      let totalStations = 0;
      let totalDestinations = 0;
      
      snapshot.forEach(doc => {
        totalStations++;
        // Count destinations from routes
        const routes = doc.data().routes || {};
        Object.keys(routes).forEach(routeId => {
          if (routeId !== 'exists') {
            totalDestinations++;
          }
        });
      });

      setStats(prev => ({
        ...prev,
        totalStations,
        totalDestinations
      }));
    });

    // Simulate cumulative passenger count
    const passengerInterval = setInterval(() => {
      setStats(prev => {
        const increment = Math.floor(Math.random() * 10) + 1;
        return {
          ...prev,
          lastPassengerCount: prev.totalPassengers,
          totalPassengers: prev.totalPassengers + increment
        };
      });
    }, 3000);

    // Simulate cumulative vehicle count
    const vehicleInterval = setInterval(() => {
      setStats(prev => {
        const change = Math.random() > 0.5 ? 1 : -1;
        const newCount = Math.max(0, prev.activeVehicles + change); // Prevent negative values
        return {
          ...prev,
          lastVehicleCount: prev.activeVehicles,
          activeVehicles: newCount
        };
      });
    }, 5000);

    // Generate chart data
    const generateChartData = () => {
      const passengerTrend = Array.from({ length: 24 }, (_, i) => ({
        hour: `${i}:00`,
        passengers: Math.floor(Math.random() * 100) + 50,
        vehicles: Math.floor(Math.random() * 30) + 20
      }));

      const vehicleUtilization = [
        { name: 'Active', value: stats.activeVehicles },
        { name: 'Maintenance', value: Math.floor(stats.activeVehicles * 0.1) },
        { name: 'Idle', value: Math.floor(stats.activeVehicles * 0.15) }
      ];

      const stationDistribution = [
        { name: 'Level 1', value: Math.floor(stats.totalStations * 0.5) },
        { name: 'Level 2', value: Math.floor(stats.totalStations * 0.3) },
        { name: 'Level 3', value: Math.floor(stats.totalStations * 0.2) }
      ];

      setChartData({
        passengerTrend,
        vehicleUtilization,
        stationDistribution
      });
    };

    generateChartData();
    const chartInterval = setInterval(generateChartData, 10000);

    return () => {
      stationsUnsubscribe();
      clearInterval(passengerInterval);
      clearInterval(vehicleInterval);
      clearInterval(chartInterval);
    };
  }, [stats.activeVehicles, stats.totalStations]);

  // Statistics Section with enhanced styling
  const StatCard = ({ icon: Icon, value, label, color }) => (
    <Paper sx={{ 
      p: 4, 
      textAlign: 'center',
      background: `linear-gradient(135deg, ${theme.background.paper} 0%, ${color}15 100%)`,
      borderRadius: '16px',
      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.18)',
      transform: 'translateY(0)',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: `0 12px 40px 0 ${color}30`
      }
    }}>
      <Box sx={{ 
        color: color,
        fontSize: 48,
        mb: 2,
        transform: 'scale(1)',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)'
        }
      }}>
        <Icon />
      </Box>
      <Typography 
        variant="h3" 
        sx={{ 
          color: theme.text.primary,
          fontSize: { xs: '2.5rem', md: '3rem' },
          fontWeight: 700,
          textShadow: `0 2px 10px ${color}40`
        }}
      >
        <CountUp 
          start={stats[`last${label.replace(/\s+/g, '')}Count`] || 0}
          end={value} 
          duration={2.5} 
          separator="," 
          preserveValue={true}
        />
      </Typography>
      <Typography 
        variant="h6" 
        sx={{ 
          color: theme.text.secondary,
          mt: 1,
          fontSize: { xs: '1.1rem', md: '1.25rem' },
          fontWeight: 500
        }}
      >
        {label}
      </Typography>
    </Paper>
  );

  // Replace the Statistics Grid with enhanced version
  const StatisticsSection = () => (
    <Grid container spacing={4} sx={{ py: 8 }} data-aos="fade-up">
      <Grid item xs={12} md={3}>
        <StatCard 
          icon={GroupIcon} 
          value={stats.totalPassengers} 
          label="Active Passengers"
          color="#4CAF50"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatCard 
          icon={LocationOnIcon} 
          value={stats.totalStations} 
          label="Service Stations"
          color="#2196F3"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatCard 
          icon={DirectionsCarIcon} 
          value={stats.activeVehicles} 
          label="Active Vehicles"
          color="#FF9800"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatCard 
          icon={PlaceIcon} 
          value={stats.totalDestinations} 
          label="Total Destinations"
          color="#9C27B0"
        />
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ 
      minHeight: '100vh',
      background: theme.background.default,
      position: 'relative',
      overflow: 'hidden'
    }}>
      <ParticleBackground />
      
      {/* Decorative Background */}
      <Box sx={backgroundPattern} />
      
      {/* Decorative Lines */}
      <Box sx={{ ...decorativeLine, left: '10%' }} />
      <Box sx={{ ...decorativeLine, left: '90%' }} />

      {/* Theme Toggle */}
      <Box sx={{ position: 'fixed', top: 20, right: 20, zIndex: 10 }}>
        <IconButton 
          onClick={toggleTheme}
          sx={{ 
            ...glassEffect,
            color: theme.text.primary,
            '&:hover': {
              background: theme.background.paperHover,
              transform: 'scale(1.1)'
            }
          }}
        >
          {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box>

      <Container maxWidth="xl" sx={{ py: 4, position: 'relative', zIndex: 1 }}>
        {/* Hero Section */}
        <MotionBox 
          sx={{ 
            textAlign: 'center',
            py: { xs: 8, md: 12 },
            position: 'relative'
          }}
          data-aos="fade-up"
        >
          <Typography 
            variant="h2" 
            sx={{ 
              color: theme.text.primary,
              mb: 2,
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                background: theme.text.secondary,
                borderRadius: 2
              }
            }}
          >
            Smart Technologies
            <Typography 
              component="span" 
              sx={{ 
                display: 'block',
                fontSize: { xs: '2rem', md: '2.5rem' },
                color: theme.text.secondary,
                mt: 1
              }}
            >
              Intelligent Vehicle Management System
            </Typography>
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              color: theme.text.secondary,
              mb: 6,
              fontSize: { xs: '1.5rem', md: '2rem' },
              maxWidth: '800px',
              mx: 'auto',
              opacity: 0.9
            }}
          >
            Modern Transportation Solutions for Ethiopia
            <Typography 
              component="span" 
              sx={{ 
                display: 'block',
                fontSize: '90%',
                mt: 1,
                opacity: 0.8
              }}
            >
              AI-driven Fleet Management and Optimization
            </Typography>
          </Typography>
          <Button 
            variant="contained"
            size="large"
            onClick={() => navigate('/login')}
            sx={{
              ...enhancedGlassEffect,
              px: { xs: 4, md: 6 },
              py: { xs: 1.5, md: 2 },
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: theme.text.primary,
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: `0 0 20px ${theme.text.secondary}40`
              }
            }}
          >
            Get Started
          </Button>
        </MotionBox>

        {/* Replace old statistics section with new one */}
        <StatisticsSection />

        {/* Real-time Analytics Section */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 6, 
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -15,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(90deg, ${theme.text.secondary}, ${theme.primary.main})`,
                borderRadius: 2
              }
            }}
          >
            Live Analytics
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Paper sx={{ 
                p: 4,
                ...glassEffect,
                height: 400
              }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.text.primary }}>
                  Passenger Trend (24 Hours)
                </Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={chartData.passengerTrend}>
                    <defs>
                      <linearGradient id="colorPassengers" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={theme.text.secondary} stopOpacity={0.8}/>
                        <stop offset="95%" stopColor={theme.text.secondary} stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.text.hint} />
                    <XAxis dataKey="hour" stroke={theme.text.secondary} />
                    <YAxis stroke={theme.text.secondary} />
                    <Tooltip 
                      contentStyle={{ 
                        background: theme.background.paper,
                        border: 'none',
                        borderRadius: '4px',
                        color: theme.text.primary
                      }}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="passengers" 
                      stroke={theme.text.secondary}
                      fillOpacity={1}
                      fill="url(#colorPassengers)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ 
                p: 4,
                ...glassEffect,
                height: 400
              }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.text.primary }}>
                  Vehicle Status
                </Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={chartData.vehicleUtilization}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {chartData.vehicleUtilization.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                    <Tooltip 
                      contentStyle={{ 
                        background: theme.background.paper,
                        border: 'none',
                        borderRadius: '4px',
                        color: theme.text.primary
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ 
                p: 4,
                ...glassEffect,
                height: 400
              }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.text.primary }}>
                  Station Distribution by Level
                </Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={chartData.stationDistribution}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.text.hint} />
                    <XAxis dataKey="name" stroke={theme.text.secondary} />
                    <YAxis stroke={theme.text.secondary} />
                    <Tooltip 
                      contentStyle={{ 
                        background: theme.background.paper,
                        border: 'none',
                        borderRadius: '4px',
                        color: theme.text.primary
                      }}
                    />
                    <Bar dataKey="value" fill={theme.text.secondary} />
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* Map Section */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 4, 
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            Our Service Network
          </Typography>
          <Paper sx={{ 
            p: 2,
            ...glassEffect,
            '&:hover': {
              background: theme.background.paperHover
            }
          }}>
            <Suspense fallback={
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                <CircularProgress sx={{ color: theme.text.secondary }} />
              </Box>
            }>
              <MapSection 
                stations={stations}
                selectedStation={selectedStation}
                onStationSelect={setSelectedStation}
                isDarkMode={isDarkMode}
              />
            </Suspense>
          </Paper>
        </Box>

        {/* Fleet Utilization Chart */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 4, 
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            Live Fleet Performance
          </Typography>
          <Paper sx={{ 
            p: 4,
            ...glassEffect,
            '&:hover': {
              background: theme.background.paperHover
            }
          }}>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={fleetData}>
                <defs>
                  <linearGradient id="colorUtilization" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={theme.text.secondary} stopOpacity={0.8}/>
                    <stop offset="95%" stopColor={theme.text.secondary} stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke={theme.text.hint} />
                <XAxis dataKey="name" stroke={theme.text.secondary} />
                <YAxis stroke={theme.text.secondary} />
                <Tooltip 
                  contentStyle={{ 
                    background: theme.background.paper,
                    border: 'none',
                    borderRadius: '4px',
                    color: theme.text.primary
                  }}
                />
                <Area 
                  type="monotone" 
                  dataKey="utilization" 
                  stroke={theme.text.secondary}
                  fillOpacity={1}
                  fill="url(#colorUtilization)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Box>

        {/* Features Grid */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 6, 
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -15,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: theme.text.secondary,
                borderRadius: 2
              }
            }}
          >
            Key Features
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper sx={{ 
                  p: 4, 
                  height: '100%',
                  ...enhancedGlassEffect,
                  transform: 'translateY(0)',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: `0 8px 16px ${theme.text.secondary}20`
                  }
                }}>
                  <Box sx={{ 
                    color: theme.text.secondary,
                    mb: 2,
                    transform: 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.1)'
                    }
                  }}>
                    {feature.icon}
                  </Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: theme.text.primary,
                      mb: 2,
                      fontSize: { xs: '1.1rem', md: '1.25rem' },
                      fontWeight: 600
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    sx={{ 
                      color: theme.text.secondary,
                      lineHeight: 1.6
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Role Features Section */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 6, 
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            Tailored Access Levels
          </Typography>
          <Grid container spacing={4}>
            {roleFeatures.map((role, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Paper sx={{ 
                  p: 4,
                  height: '100%',
                  ...glassEffect,
                  '&:hover': {
                    background: theme.background.paperHover
                  }
                }}>
                  <Typography variant="h5" sx={{ color: theme.text.secondary, mb: 2 }}>
                    {role.title}
                  </Typography>
                  <Typography sx={{ mb: 3, color: theme.text.hint }}>
                    {role.description}
                  </Typography>
                  {role.items.map((item, i) => (
                    <Typography 
                      key={i} 
                      sx={{ 
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.text.primary,
                        '&:before': {
                          content: '"•"',
                          marginRight: '8px',
                          color: theme.text.secondary
                        }
                      }}
                    >
                      {item}
                    </Typography>
                  ))}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 6,
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -15,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(90deg, ${theme.text.secondary}, ${theme.primary.main})`,
                borderRadius: 2
              }
            }}
          >
            What Our Clients Say
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                name: "Tewodros Bekele",
                role: "Transport Director",
                company: "Ethiopian Cargo Express",
                content: "As a major logistics provider in Ethiopia, we needed a system that understands our local transport ecosystem. Smart Technologies' platform has improved our fleet efficiency across all regions, especially during peak seasons.",
                image: "TB"
              },
              {
                name: "Hirut Alemayehu",
                role: "Operations Director",
                company: "Addis Metropolitan Transit",
                content: "The interface and local support team made implementation smooth for our drivers. Real-time tracking in Addis Ababa's busy streets has revolutionized our service delivery.",
                image: "HA"
              },
              {
                name: "Fekadu Tessema",
                role: "General Manager",
                company: "Oromia Transport Solutions",
                content: "Smart Technologies understands Ethiopian transport challenges. Their system helped us manage our regional operations effectively, with excellent support across all regions.",
                image: "FT"
              }
            ].map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index} data-aos="fade-up" data-aos-delay={index * 100}>
                <Paper sx={{ 
                  p: 4,
                  height: '100%',
                  ...enhancedGlassEffect,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  <Box 
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      mb: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: theme.primary.main,
                      color: theme.text.primary,
                      fontSize: '2rem',
                      fontWeight: 'bold',
                      boxShadow: `0 4px 8px ${theme.text.secondary}20`,
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        boxShadow: `0 8px 16px ${theme.text.secondary}40`
                      }
                    }}
                  >
                    {testimonial.image}
                  </Box>
                  <Typography sx={{ 
                    color: theme.text.secondary,
                    fontStyle: 'italic',
                    mb: 3,
                    lineHeight: 1.8,
                    position: 'relative',
                    zIndex: 1
                  }}>
                    "{testimonial.content}"
                  </Typography>
                  <Box sx={{ mt: 'auto' }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        color: theme.text.primary,
                        mb: 1,
                        fontWeight: 600
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                    <Typography sx={{ 
                      color: theme.text.secondary,
                      fontWeight: 500,
                      mb: 1
                    }}>
                      {testimonial.role}
                    </Typography>
                    <Typography sx={{ 
                      color: theme.accent || theme.text.secondary,
                      fontSize: '0.9rem'
                    }}>
                      {testimonial.company}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Contact Us Section */}
        <Box sx={{ py: 8 }} data-aos="fade-up">
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 6,
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            Contact Us
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ 
                p: 4,
                height: '100%',
                ...enhancedGlassEffect
              }}>
                <Typography variant="h5" sx={{ color: theme.text.primary, mb: 3 }}>
                  Get in Touch
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: theme.text.secondary
                          },
                          '&:hover fieldset': {
                            borderColor: theme.text.primary
                          }
                        },
                        '& .MuiInputLabel-root': {
                          color: theme.text.secondary
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      type="email"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: theme.text.secondary
                          },
                          '&:hover fieldset': {
                            borderColor: theme.text.primary
                          }
                        },
                        '& .MuiInputLabel-root': {
                          color: theme.text.secondary
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: theme.text.secondary
                          },
                          '&:hover fieldset': {
                            borderColor: theme.text.primary
                          }
                        },
                        '& .MuiInputLabel-root': {
                          color: theme.text.secondary
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button 
                      variant="contained"
                      fullWidth
                      sx={{
                        ...enhancedGlassEffect,
                        py: 1.5,
                        color: theme.text.primary,
                        fontSize: '1.1rem'
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ 
                p: 4,
                height: '100%',
                ...enhancedGlassEffect
              }}>
                <Typography variant="h5" sx={{ color: theme.text.primary, mb: 3 }}>
                  Contact Information
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <LocationOnOutlinedIcon sx={{ color: theme.text.secondary, mr: 2 }} />
                    <Typography sx={{ color: theme.text.primary }}>
                      Adama, Ethiopia
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <EmailIcon sx={{ color: theme.text.secondary, mr: 2 }} />
                    <Typography sx={{ color: theme.text.primary }}>
                      info@smarttech.et
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PhoneIcon sx={{ color: theme.text.secondary, mr: 2 }} />
                    <Typography sx={{ color: theme.text.primary }}>
                      +251 939540127<br />
                      +251 910960528<br />
                      +251 913429438
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
                  Business Hours
                </Typography>
                <Typography sx={{ color: theme.text.secondary, mb: 1 }}>
                  Monday - Friday: 8:30 AM - 5:30 PM
                </Typography>
                <Typography sx={{ color: theme.text.secondary }}>
                  Saturday: 9:00 AM - 1:00 PM
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* CTA Section */}
        <Box 
          sx={{ 
            py: 12, 
            textAlign: 'center',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              height: 1,
              background: `linear-gradient(90deg, transparent, ${theme.text.secondary}40, transparent)`
            }
          }} 
          data-aos="fade-up"
        >
          <Typography 
            variant="h3" 
            sx={{ 
              color: theme.text.primary,
              mb: 4,
              fontSize: { xs: '2rem', md: '2.5rem' },
              textShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            Ready to Get Started?
          </Typography>
          <Button 
            variant="contained"
            size="large"
            onClick={() => navigate('/login')}
            sx={{
              ...enhancedGlassEffect,
              px: { xs: 4, md: 6 },
              py: { xs: 1.5, md: 2 },
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: theme.text.primary,
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: `0 0 20px ${theme.text.secondary}40`
              }
            }}
          >
            Sign Up Now
          </Button>
        </Box>

        {/* Footer */}
        <Box 
          component="footer" 
          sx={{ 
            py: 6,
            mt: 8,
            borderTop: `1px solid ${theme.text.secondary}40`,
            position: 'relative'
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
                Smart Technologies
              </Typography>
              <Typography sx={{ color: theme.text.secondary, mb: 2 }}>
                Leading the future of intelligent vehicle management with cutting-edge technology and innovation.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
                Quick Links
              </Typography>
              <List>
                <ListItem disablePadding>
                  <ListItemButton 
                    component={Link} 
                    to="/about"
                    sx={{
                      color: theme.text.secondary,
                      '&:hover': {
                        color: theme.text.primary,
                        backgroundColor: 'transparent',
                        transform: 'translateX(5px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <ListItemIcon>
                      <InfoOutlinedIcon sx={{ color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText primary="About Us" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton 
                    component={Link} 
                    to="/services"
                    sx={{
                      color: theme.text.secondary,
                      '&:hover': {
                        color: theme.text.primary,
                        backgroundColor: 'transparent',
                        transform: 'translateX(5px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <ListItemIcon>
                      <LocalGasStationIcon sx={{ color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText primary="Services" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton 
                    component={Link} 
                    to="/contact"
                    sx={{
                      color: theme.text.secondary,
                      '&:hover': {
                        color: theme.text.primary,
                        backgroundColor: 'transparent',
                        transform: 'translateX(5px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <ListItemIcon>
                      <ContactSupportIcon sx={{ color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText primary="Contact" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: theme.text.primary, mb: 2 }}>
                Connect With Us
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <IconButton 
                  component="a"
                  href="https://www.facebook.com"
                  target="_blank"
                  sx={{ 
                    color: theme.text.secondary,
                    '&:hover': { 
                      color: '#1877F2',
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton 
                  component="a"
                  href="https://www.twitter.com"
                  target="_blank"
                  sx={{ 
                    color: theme.text.secondary,
                    '&:hover': { 
                      color: '#1DA1F2',
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton 
                  component="a"
                  href="https://www.linkedin.com"
                  target="_blank"
                  sx={{ 
                    color: theme.text.secondary,
                    '&:hover': { 
                      color: '#0A66C2',
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton 
                  component="a"
                  href="https://www.instagram.com"
                  target="_blank"
                  sx={{ 
                    color: theme.text.secondary,
                    '&:hover': { 
                      color: '#E4405F',
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  <InstagramIcon />
                </IconButton>
              </Box>
              <Typography 
                sx={{ 
                  color: theme.text.secondary,
                  textAlign: 'center',
                  mt: 4,
                  fontSize: '0.9rem'
                }}
              >
                &copy; {new Date().getFullYear()} Smart Technologies Company. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
