import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  List, 
  ListItem, 
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Tooltip,
  DialogContentText,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Divider,
  alpha,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PlaceIcon from '@mui/icons-material/Place';
import TimelineIcon from '@mui/icons-material/Timeline';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend
} from 'recharts';
import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const StationsPage = () => {
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [expandedLevels, setExpandedLevels] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filterLevel, setFilterLevel] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [statsData, setStatsData] = useState({
    totalStations: 0,
    activeRoutes: 0,
    totalRoutes: 0,
    totalDestinations: 0,
    averagePrice: 0
  });
  const theme = useTheme();
  const [chartData, setChartData] = useState({
    routesPerLevel: [],
    activeVsInactive: [],
    priceDistribution: []
  });

  useEffect(() => {
    fetchStations();
  }, []);

  useEffect(() => {
    if (stations.length > 0) {
      calculateStats();
    }
  }, [stations]);

  const calculateStats = () => {
    let activeRoutes = 0;
    let totalRoutes = 0;
    let totalPrice = 0;
    let totalDestinations = 0;
    const routesPerLevel = { 1: 0, 2: 0, 3: 0 };
    const priceRanges = { '0-50': 0, '51-100': 0, '101-150': 0, '150+': 0 };

    stations.forEach(station => {
      Object.entries(station.routes).forEach(([routeId, route]) => {
        if (routeId !== 'exists') {
          totalRoutes++;
          totalDestinations++;
          if (route.exists) activeRoutes++;
          if (route.netPrice) {
            totalPrice += parseFloat(route.netPrice);
            const price = parseFloat(route.netPrice);
            if (price <= 50) priceRanges['0-50']++;
            else if (price <= 100) priceRanges['51-100']++;
            else if (price <= 150) priceRanges['101-150']++;
            else priceRanges['150+']++;
          }
          
          const levelMatch = routeId.match(/Level-(\d+)-/);
          if (levelMatch) {
            routesPerLevel[levelMatch[1]]++;
          }
        }
      });
    });

    setStatsData({
      totalStations: stations.length,
      activeRoutes,
      totalRoutes,
      totalDestinations,
      averagePrice: totalRoutes > 0 ? (totalPrice / totalRoutes).toFixed(2) : 0
    });

    setChartData({
      routesPerLevel: Object.entries(routesPerLevel).map(([level, count]) => ({
        level: `Level ${level}`,
        count
      })),
      activeVsInactive: [
        { name: 'Active', value: activeRoutes },
        { name: 'Inactive', value: totalRoutes - activeRoutes }
      ],
      priceDistribution: Object.entries(priceRanges).map(([range, count]) => ({
        range,
        count
      }))
    });
  };

  const fetchStationRoutes = async (stationId) => {
    try {
      const routesCollection = collection(db, 'Allroutes', stationId, 'routes');
      const routesSnapshot = await getDocs(routesCollection);
      const routesData = {};
      
      routesSnapshot.docs.forEach(doc => {
        if (doc.id !== 'exists') {  // Skip the 'exists' document
          routesData[doc.id] = doc.data();
        }
      });
      
      console.log(`Routes data for ${stationId}:`, routesData);
      return routesData;
    } catch (err) {
      console.error(`Error fetching routes for station ${stationId}:`, err);
      return {};
    }
  };

  const fetchStations = async () => {
    try {
      const stationsSnapshot = await getDocs(collection(db, 'Allroutes'));
      const stationsPromises = stationsSnapshot.docs.map(async doc => {
        const routes = await fetchStationRoutes(doc.id);
        console.log(`Routes for ${doc.id}:`, routes); // Debug log
        return {
          id: doc.id,
          routes: routes
        };
      });
      
      const stationsData = await Promise.all(stationsPromises);
      console.log('All stations data:', stationsData); // Debug log
      setStations(stationsData);
    } catch (err) {
      setError('Failed to fetch stations data');
      console.error('Error fetching stations:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (station) => {
    setSelectedStation(station);
    setEditForm({
      name: station.id,
      routes: station.routes || {}
    });
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (station) => {
    setSelectedStation(station);
    setDeleteDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setSelectedStation(null);
    setEditForm({});
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
    setSelectedStation(null);
  };

  const handleRouteChange = (routeId, field, value) => {
    setEditForm(prev => ({
      ...prev,
      routes: {
        ...prev.routes,
        [routeId]: {
          ...prev.routes[routeId],
          [field]: value
        }
      }
    }));
  };

  const handleActivateAll = async () => {
    try {
      const routesCollection = collection(db, 'Allroutes', selectedStation.id, 'routes');
      const updatePromises = Object.entries(selectedStation.routes).map(([routeId, routeData]) => {
        if (routeId !== 'exists') {
          const routeRef = doc(routesCollection, routeId);
          return updateDoc(routeRef, { exists: true });
        }
      }).filter(Boolean);
      
      await Promise.all(updatePromises);
      await fetchStations();
      handleEditClose();
    } catch (err) {
      console.error('Error activating all routes:', err);
      setError('Failed to activate all routes');
    }
  };

  const handleEditSave = async () => {
    try {
      const routesCollection = collection(db, 'Allroutes', selectedStation.id, 'routes');
      const updatePromises = Object.entries(editForm.routes).map(([routeId, routeData]) => {
        const routeRef = doc(routesCollection, routeId);
        return updateDoc(routeRef, routeData);
      });
      
      await Promise.all(updatePromises);
      await fetchStations();
      handleEditClose();
    } catch (err) {
      console.error('Error updating station:', err);
      setError('Failed to update station');
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const stationRef = doc(db, 'Allroutes', selectedStation.id);
      await deleteDoc(stationRef);
      await fetchStations();
      handleDeleteClose();
    } catch (err) {
      console.error('Error deleting station:', err);
      setError('Failed to delete station');
    }
  };

  const groupRoutesByLevel = (routes) => {
    if (!routes) return {};
    
    const levels = {};
    Object.entries(routes).forEach(([routeId, routeData]) => {
      if (typeof routeId === 'string' && routeId.startsWith('Level-')) {
        const levelMatch = routeId.match(/Level-(\d+)-/);
        if (levelMatch) {
          const level = levelMatch[1];
          if (!levels[level]) {
            levels[level] = {};
          }
          levels[level][routeId] = routeData;
        }
      }
    });
    return levels;
  };

  const filteredStations = stations.filter(station => {
    const matchesSearch = station.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      Object.values(station.routes).some(route => 
        route.destination?.toLowerCase().includes(searchTerm.toLowerCase())
      );

    if (!matchesSearch) return false;

    if (filterStatus !== 'all') {
      const hasMatchingStatus = Object.values(station.routes).some(route => 
        filterStatus === 'active' ? route.exists : !route.exists
      );
      if (!hasMatchingStatus) return false;
    }

    if (filterLevel !== 'all') {
      const hasMatchingLevel = Object.keys(station.routes).some(routeId => 
        routeId.includes(`Level-${filterLevel}`)
      );
      if (!hasMatchingLevel) return false;
    }

    return true;
  });

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const handleLevelExpand = (stationId, level) => {
    setExpandedLevels(prev => ({
      ...prev,
      [stationId]: prev[stationId] === level ? null : level
    }));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      p: 3, 
      backgroundColor: '#f8f9fa',
      minHeight: '100vh'
    }}>
      {/* Dashboard Header */}
      <Paper 
        elevation={3}
        sx={{ 
          p: 3, 
          mb: 3, 
          borderRadius: 3,
          background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)'
        }}
      >
        <Typography variant="h4" gutterBottom fontWeight="bold" sx={{ color: '#1a237e' }}>
          Transport Stations Dashboard
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ 
              p: 2.5, 
              borderRadius: 2,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              background: '#ffffff',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)'
              }
            }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <LocationOnIcon sx={{ color: '#2196f3', fontSize: 32 }} />
                <Box>
                  <Typography variant="body2" sx={{ color: '#666', fontWeight: 500 }}>Total Stations</Typography>
                  <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 600 }}>{statsData.totalStations}</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ 
              p: 2.5, 
              borderRadius: 2,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              background: '#ffffff',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)'
              }
            }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <PlaceIcon sx={{ color: '#4caf50', fontSize: 32 }} />
                <Box>
                  <Typography variant="body2" sx={{ color: '#666', fontWeight: 500 }}>Total Destinations</Typography>
                  <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 600 }}>{statsData.totalDestinations}</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ 
              p: 2.5, 
              borderRadius: 2,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              background: '#ffffff',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)'
              }
            }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <DirectionsBusIcon sx={{ color: '#ff9800', fontSize: 32 }} />
                <Box>
                  <Typography variant="body2" sx={{ color: '#666', fontWeight: 500 }}>Active Routes</Typography>
                  <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 600 }}>{statsData.activeRoutes}/{statsData.totalRoutes}</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ 
              p: 2.5, 
              borderRadius: 2,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              background: '#ffffff',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)'
              }
            }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <MonetizationOnIcon sx={{ color: '#9c27b0', fontSize: 32 }} />
                <Box>
                  <Typography variant="body2" sx={{ color: '#666', fontWeight: 500 }}>Average Price</Typography>
                  <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 600 }}>{statsData.averagePrice} ETB</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      {/* Charts Section */}
      <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3, fontWeight: 600 }}>
          Analytics Overview
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 2, 
              height: 300,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              borderRadius: 2
            }}>
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#1a237e', fontWeight: 500 }}>Routes by Level</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData.routesPerLevel}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis dataKey="level" stroke="#666" />
                  <YAxis stroke="#666" />
                  <RechartsTooltip />
                  <Bar dataKey="count" fill="#2196f3" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 2, 
              height: 300,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              borderRadius: 2
            }}>
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#1a237e', fontWeight: 500 }}>Active vs Inactive Routes</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={chartData.activeVsInactive}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {chartData.activeVsInactive.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend />
                  <RechartsTooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 2, 
              height: 300,
              boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
              borderRadius: 2
            }}>
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#1a237e', fontWeight: 500 }}>Price Distribution</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData.priceDistribution}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis dataKey="range" stroke="#666" />
                  <YAxis stroke="#666" />
                  <RechartsTooltip />
                  <Line type="monotone" dataKey="count" stroke="#4caf50" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      {/* Search and Filters */}
      <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3, fontWeight: 600 }}>
          Search and Filters
        </Typography>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Search stations or destinations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#666' }} />
                  </InputAdornment>
                ),
              }}
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#ffffff',
                  '&:hover fieldset': {
                    borderColor: '#2196f3',
                  },
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Filter by Level</InputLabel>
              <Select
                value={filterLevel}
                onChange={(e) => setFilterLevel(e.target.value)}
                label="Filter by Level"
                sx={{ backgroundColor: '#ffffff' }}
              >
                <MenuItem value="all">All Levels</MenuItem>
                <MenuItem value="1">Level 1</MenuItem>
                <MenuItem value="2">Level 2</MenuItem>
                <MenuItem value="3">Level 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                label="Filter by Status"
                sx={{ backgroundColor: '#ffffff' }}
              >
                <MenuItem value="all">All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Stations Grid */}
      <Paper elevation={3} sx={{ p: 3, borderRadius: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3, fontWeight: 600 }}>
          Stations Overview
        </Typography>
        <Grid container spacing={3}>
          {filteredStations.map((station) => (
            <Grid item xs={12} md={6} key={station.id}>
              <Card 
                sx={{ 
                  borderRadius: 2,
                  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)'
                  }
                }}
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <LocationOnIcon sx={{ color: '#2196f3' }} />
                      <Typography variant="h6" sx={{ color: '#1a237e', fontWeight: 600 }}>
                        {station.id}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Edit Station">
                        <IconButton 
                          onClick={() => handleEditClick(station)} 
                          sx={{ 
                            color: '#2196f3',
                            '&:hover': { 
                              backgroundColor: 'rgba(33, 150, 243, 0.1)'
                            }
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Station">
                        <IconButton 
                          onClick={() => handleDeleteClick(station)}
                          sx={{ 
                            color: '#f44336',
                            '&:hover': { 
                              backgroundColor: 'rgba(244, 67, 54, 0.1)'
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                  {Object.keys(groupRoutesByLevel(station.routes)).length > 0 ? (
                    <List>
                      {Object.entries(groupRoutesByLevel(station.routes)).map(([level, levelRoutes]) => (
                        <Accordion 
                          key={level} 
                          expanded={expandedLevels[station.id] === level} 
                          onChange={() => handleLevelExpand(station.id, level)}
                          sx={{
                            boxShadow: 'none',
                            '&:before': {
                              display: 'none',
                            },
                            '& .MuiAccordionSummary-root': {
                              borderRadius: 1,
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              },
                            }
                          }}
                        >
                          <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                              backgroundColor: 'rgba(0, 0, 0, 0.02)',
                              borderRadius: 1,
                            }}
                          >
                            <Typography variant="subtitle1" sx={{ color: '#1a237e', fontWeight: 500 }}>
                              Level {level}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {Object.entries(levelRoutes).map(([routeId, routeData]) => (
                                <ListItem 
                                  key={routeId}
                                  sx={{
                                    borderRadius: 1,
                                    mb: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                  }}
                                >
                                  <ListItemText 
                                    primary={
                                      <Box display="flex" alignItems="center" gap={1}>
                                        <Typography variant="subtitle1" sx={{ color: '#1a237e' }}>
                                          {routeData.destination || routeId.replace(`Level-${level}-${station.id}-`, '')}
                                        </Typography>
                                        <Chip 
                                          label={routeData.exists ? "Active" : "Inactive"} 
                                          color={routeData.exists ? "success" : "default"}
                                          size="small"
                                          sx={{ 
                                            fontWeight: 500,
                                            backgroundColor: routeData.exists ? 'rgba(76, 175, 80, 0.1)' : 'rgba(0, 0, 0, 0.08)',
                                            color: routeData.exists ? '#2e7d32' : '#666'
                                          }}
                                        />
                                      </Box>
                                    }
                                    secondary={
                                      <Stack spacing={0.5} mt={1}>
                                        {routeData.distanceKM && (
                                          <Typography variant="body2" sx={{ color: '#666' }}>
                                            Distance: {routeData.distanceKM} KM
                                          </Typography>
                                        )}
                                        {routeData.price && (
                                          <Typography variant="body2" sx={{ color: '#666' }}>
                                            Price: {routeData.price} ETB
                                            {routeData.serviceCharge && ` + ${routeData.serviceCharge} ETB (Service)`}
                                          </Typography>
                                        )}
                                        {routeData.netPrice && (
                                          <Typography variant="body2" sx={{ color: '#1a237e', fontWeight: 500 }}>
                                            Total: {routeData.netPrice} ETB
                                          </Typography>
                                        )}
                                      </Stack>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </List>
                  ) : (
                    <Typography sx={{ color: '#666' }}>
                      No routes available for this station
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Edit Dialog */}
      <Dialog 
        open={editDialogOpen} 
        onClose={handleEditClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3
          }
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Edit Station: {selectedStation?.id}</Typography>
            <Button 
              variant="contained" 
              color="success" 
              onClick={handleActivateAll}
              size="small"
            >
              Activate All Routes
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            {selectedStation?.routes && Object.entries(groupRoutesByLevel(selectedStation.routes)).map(([level, levelRoutes]) => (
              <Accordion key={level}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">Level {level}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(levelRoutes).map(([routeId, routeData]) => (
                    <Box key={routeId} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={editForm.routes?.[routeId]?.exists || false}
                            onChange={(e) => handleRouteChange(routeId, 'exists', e.target.checked)}
                          />
                        }
                        label="Active"
                      />
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Destination"
                            value={editForm.routes?.[routeId]?.destination || ''}
                            onChange={(e) => handleRouteChange(routeId, 'destination', e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Distance (KM)"
                            value={editForm.routes?.[routeId]?.distanceKM || ''}
                            onChange={(e) => handleRouteChange(routeId, 'distanceKM', e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Price (ETB)"
                            value={editForm.routes?.[routeId]?.price || ''}
                            onChange={(e) => handleRouteChange(routeId, 'price', e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Service Charge (ETB)"
                            value={editForm.routes?.[routeId]?.serviceCharge || ''}
                            onChange={(e) => handleRouteChange(routeId, 'serviceCharge', e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Total Price (ETB)"
                            value={editForm.routes?.[routeId]?.netPrice || ''}
                            onChange={(e) => handleRouteChange(routeId, 'netPrice', e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={deleteDialogOpen} 
        onClose={handleDeleteClose}
        PaperProps={{
          sx: {
            borderRadius: 3
          }
        }}
      >
        <DialogTitle>Delete Station</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the station "{selectedStation?.id}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StationsPage;
