import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Make sure to configure Firebase properly
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";

const InventoryManagement = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    description: "",
    quantity: "",
    assignedTo: "",
    properties: [],
    category: "",
  });

  const [inventoryItems, setInventoryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [lowStockThreshold, setLowStockThreshold] = useState(10);

  const inventoryCollectionRef = collection(db, "inventory");

  // Fetch inventory items from Firestore
  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      try {
        const data = await getDocs(inventoryCollectionRef);
        const items = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInventoryItems(items);
      } catch (error) {
        console.error("Error fetching inventory:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "properties") {
      setFormData((prev) => ({
        ...prev,
        properties: value.split(","),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.type || !formData.name || !formData.quantity) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      await addDoc(inventoryCollectionRef, {
        ...formData,
        createdAt: new Date(),
      });
      setFormData({
        type: "",
        name: "",
        description: "",
        quantity: "",
        assignedTo: "",
        properties: [],
        category: "",
      });
      alert("Item added successfully!");
      // Reload items
      const data = await getDocs(inventoryCollectionRef);
      const items = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInventoryItems(items);
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  // Handle delete item
  const handleDelete = async (id) => {
    try {
      const itemDoc = doc(db, "inventory", id);
      await deleteDoc(itemDoc);
      alert("Item deleted successfully!");
      setInventoryItems((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  // Filter and search functionality
  const filteredItems = inventoryItems.filter((item) => {
    const matchesSearch = item.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCategory =
      categoryFilter === "All" || item.category === categoryFilter;
    return matchesSearch && matchesCategory;
  });

  // Low stock items
  const lowStockItems = inventoryItems.filter(
    (item) => parseInt(item.quantity, 10) < lowStockThreshold
  );

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <h1 style={{ textAlign: "center" }}>Inventory Management</h1>
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        <select
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
          style={{ marginRight: "10px" }}
        >
          <option value="All">All Categories</option>
          <option value="Electronics">Electronics</option>
          <option value="Furniture">Furniture</option>
          <option value="Stationery">Stationery</option>
        </select>
        <input
          type="number"
          placeholder="Low Stock Threshold"
          value={lowStockThreshold}
          onChange={(e) => setLowStockThreshold(e.target.value)}
        />
      </div>

      {/* Add Item Form */}
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Type (e.g., Device, Employee, Property):
          <input
            type="text"
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Description:
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Quantity:
          <input
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            required
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Assigned To:
          <input
            type="text"
            name="assignedTo"
            value={formData.assignedTo}
            onChange={handleChange}
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Category:
          <input
            type="text"
            name="category"
            value={formData.category}
            onChange={handleChange}
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Properties (comma-separated):
          <input
            type="text"
            name="properties"
            value={formData.properties.join(",")}
            onChange={handleChange}
          />
        </label>
        <button type="submit" style={{ float: "right", marginRight: "10px" }}>
          Add Item
        </button>
      </form>

      {/* Low Stock Alerts */}
      <h2 style={{ marginTop: "20px" }}>Low Stock Alerts</h2>
      {lowStockItems.length === 0 ? (
        <p>No low stock items.</p>
      ) : (
        <ul>
          {lowStockItems.map((item) => (
            <li key={item.id}>
              {item.name} (Quantity: {item.quantity})
            </li>
          ))}
        </ul>
      )}

      {/* Display Inventory */}
      {loading ? (
        <p>Loading inventory...</p>
      ) : (
        <div>
          <h2 style={{ marginTop: "20px" }}>Inventory Items</h2>
          {filteredItems.length === 0 ? (
            <p>No items found.</p>
          ) : (
            <table border="1" cellPadding="5" cellSpacing="0">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Assigned To</th>
                  <th>Category</th>
                  <th>Properties</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.type}</td>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>{item.quantity}</td>
                    <td>{item.assignedTo}</td>
                    <td>{item.category}</td>
                    <td>{item.properties.join(",")}</td>
                    <td>
                      <button onClick={() => handleDelete(item.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default InventoryManagement;
