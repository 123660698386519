import { Box } from '@mui/material';
import React from 'react';
import Sidebar from '../common/Sidebar';
import { Outlet } from 'react-router-dom';

const sidebarWidth = 350;

const MainLayout = () => {
  return (
    <Box
      sx={{
        display: { xs: 'block', sm: 'flex' },
        minHeight: '100vh',
        backgroundColor: 'grey.100'
      }}
    >
      {/* sidebar */}
      <Sidebar sidebarWidth={sidebarWidth} />
      {/* sidebar */}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${sidebarWidth}px)` },
          ml: { sm: `${sidebarWidth}px` },
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;