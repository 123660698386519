import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import AdminIcon from'@mui/icons-material/AdminPanelSettings';
import AddIcon from'@mui/icons-material/InsertChart';

import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import SportsMotorsportsOutlinedIconb from '@mui/icons-material/Receipt';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, colors } from '@mui/material';
import { images } from "../../assets";
import Animate from "./Animate";
import { BusAlertOutlined } from '@mui/icons-material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth, db } from '../../firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const adminMenus = [
  {
    title: "Admin Controller",
    icon: <AdminIcon />,
    state: "inbox",
    path: "/dashboard/inbox"
  },
  {
    title: "Overview",
    icon: <DashboardCustomizeOutlinedIcon />,
    state: "overview",
    path: "/dashboard/overview"
  },
  {
    title: "Add Users",
    icon: <PersonAddIcon />,
    state: "AddUserAndVehicle",
    path: "/dashboard/AddUserAndVehicle"
  },
  {
    title: "Add Cars",
    icon: <DirectionsCarIcon />,
    state: "AddVehicle",
    path: "/dashboard/AddVehicle"
  },
  {
    title: "Inventory Management",
    icon: <InventoryIcon />,
    state: "InventoryManagement",
    path: "/dashboard/InventoryManagement"
  }
];

const serviceMenus = [
  {
    title: "Active Sales",
    icon: <OtherHousesOutlinedIcon />,
    state: "mortage",
    path: "/dashboard/mortage"
  },
  {
    title: "Sales Activity",
    icon: <OtherHousesOutlinedIcon />,
    state: "SalesActivity",
    path: "/dashboard/SalesActivity"
  },
  {
    title: "Active Cars",
    icon: <DirectionsCarFilledOutlinedIcon />,
    state: "carloan",
    path: "/dashboard/carloan"
  },
  {
    title: "Tickets",
    icon: <SportsMotorsportsOutlinedIconb />,
    state: "ticketsb",
    path: "/dashboard/ticketsb"
  },
  {
    title: "Today Tickets",
    icon: <SportsMotorsportsOutlinedIcon />,
    state: "tickets",
    path: "/dashboard/tickets"
  },
  {
    title: "Stations",
    icon: <BusAlertOutlined />,
    state: "stations",
    path: "/dashboard/stations"
  }
];

const guestMenus = [
  {
    title: "Today Tickets",
    icon: <SportsMotorsportsOutlinedIcon />,
    state: "tickets",
    path: "/dashboard/tickets"
  },
  {
    title: "Active Cars",
    icon: <DirectionsCarFilledOutlinedIcon />,
    state: "carloan",
    path: "/dashboard/carloan"
  }
];

const Sidebar = ({ sidebarWidth }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [userRole, setUserRole] = useState('');
  let activeState = location.pathname.split('/').pop();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        // Get display name from Auth
        if (currentUser.displayName) {
          setDisplayName(currentUser.displayName);
        }
        
        // Try to get user data from Firestore
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            if (data.displayName) {
              setDisplayName(data.displayName);
            }
            setUserRole(data.role || (currentUser.email === 'abrahamwondwosen@gmail.com' ? 'admin' : 'guest'));
          } else {
            setUserRole(currentUser.email === 'abrahamwondwosen@gmail.com' ? 'admin' : 'guest');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserRole(currentUser.email === 'abrahamwondwosen@gmail.com' ? 'admin' : 'guest');
        }
      } else {
        setUser(null);
        setDisplayName('');
        setUserRole('');
      }
    });
    return () => unsubscribe();
  }, []);

  // Check if the activeState is a valid menu state
  const allMenuStates = [...adminMenus, ...(userRole === 'admin' ? serviceMenus : guestMenus)].map(menu => menu.state);
  if (!allMenuStates.includes(activeState)) {
    activeState = userRole === 'admin' ? "overview" : "tickets";
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const MenuItem = ({ item }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === item.path;

    const handleClick = () => {
      navigate(item.path);
    };

    return (
      <ListItemButton 
        onClick={handleClick}
        sx={{
          borderRadius: "10px",
          bgcolor: isActive ? colors.green[600] : "transparent",
          color: isActive ? colors.common.white : colors.grey[700],
          "&:hover": {
            bgcolor: isActive ? colors.green[600] : colors.grey[100],
            color: isActive ? colors.common.white : colors.grey[900],
          }
        }}
      >
        <ListItemIcon sx={{
          minWidth: "40px",
          color: isActive ? colors.common.white : colors.grey[700]
        }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={
          <Typography fontWeight={600}>
            {item.title}
          </Typography>
        } />
      </ListItemButton>
    );
  };

  const drawer = (
    <Box
      component="nav"
      sx={{
        width: { sm: sidebarWidth },
        flexShrink: { sm: 0 },
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          background: colors.grey[300],
          borderRadius: '4px',
          '&:hover': {
            background: colors.grey[400]
          }
        },
        zIndex: 1200,
        backgroundColor: colors.common.white,
        borderRight: `1px solid ${colors.grey[200]}`
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: "16px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            padding: "12px 20px",
            backgroundColor: colors.common.white,
            boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
          }}
        >
          {/* logo */}
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Animate type="fade" delay={1}>
              <img src={images.logo} alt="logo" height={60} />
            </Animate>
          </Box>

          {/* Admin Menus */}
          {userRole === 'admin' && (
            <List sx={{ mb: 2 }}>
              {adminMenus.map((item, index) => (
                <ListItem key={index} disableGutters disablePadding sx={{ py: 0.5 }}>
                  <MenuItem item={item} />
                </ListItem>
              ))}
            </List>
          )}

          {/* Services Section */}
          <List sx={{ mb: 2 }}>
            <ListItem>
              <Typography fontWeight={600} color={colors.grey[600]}>
                Services
              </Typography>
            </ListItem>
            {(userRole === 'admin' ? serviceMenus : guestMenus).map((item, index) => (
              <ListItem key={index} disableGutters disablePadding sx={{ py: 0.5 }}>
                <MenuItem item={item} />
              </ListItem>
            ))}
          </List>

          {/* User Profile and Logout Section */}
          <Box sx={{ mt: 'auto', borderTop: 1, borderColor: 'divider', pt: 2 }}>
            <List>
              <ListItem disableGutters disablePadding>
                <ListItemButton 
                  component={Link} 
                  to="/dashboard/profile"
                  sx={{
                    borderRadius: "10px",
                    "&:hover": {
                      bgcolor: colors.grey[100],
                    }
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleIcon sx={{ color: colors.grey[700] }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Box>
                        <Typography fontWeight={600} color={colors.grey[700]}>
                          {userRole === 'admin' ? (displayName || 'My Profile') : 'Guest User'}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: colors.grey[600],
                            textTransform: 'capitalize',
                            display: 'block',
                            mt: 0.5
                          }}
                        >
                          {userRole}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemButton 
                  onClick={handleLogout}
                  sx={{
                    borderRadius: "10px",
                    "&:hover": {
                      bgcolor: colors.red[50],
                      "& .MuiListItemIcon-root": {
                        color: colors.red[700]
                      },
                      "& .MuiTypography-root": {
                        color: colors.red[700]
                      }
                    }
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: colors.grey[700] }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography fontWeight={600} color={colors.grey[700]}>
                        Logout
                      </Typography>
                    } 
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: sidebarWidth },
        flexShrink: { sm: 0 },
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          background: colors.grey[300],
          borderRadius: '4px',
          '&:hover': {
            background: colors.grey[400]
          }
        },
        zIndex: 1200,
        backgroundColor: colors.common.white,
        borderRight: `1px solid ${colors.grey[200]}`
      }}
    >
      {drawer}
    </Box>
  );
};

export default Sidebar;
