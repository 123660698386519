import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VisibilityOff, Visibility, DarkMode, LightMode } from "@mui/icons-material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { images } from "../assets";
import { motion } from "framer-motion";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styled from 'styled-components';

const GlassBox = styled(motion.div)`
  background: rgba(17, 25, 40, 0.85);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  padding: 48px;
  width: 150%;
  max-width: 420px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
`;

// const FloatingParticle = styled(motion.div)`
//   position: absolute;
//   width: 18px;
//   height: 18px;
//   background: rgba(100, 116, 139, 0.6);
//   border-radius: 50%;
//   box-shadow: 0 0 10px rgba(100, 116, 139, 0.3);
// `;
const FloatingParticle = styled(motion.div)`
  position: absolute;
  width: 36px;
  height: 36px;
  background: rgba(100, 116, 139, 0.6);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(100, 116, 139, 0.3);
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    background: rgba(30, 41, 59, 0.5);
    border-radius: 12px;
    transition: all 0.3s ease;
    
    & fieldset {
      border-color: rgba(148, 163, 184, 0.2);
    }
    
    &:hover fieldset {
      border-color: rgba(148, 163, 184, 0.4);
    }
    
    &.Mui-focused {
      background: rgba(30, 41, 59, 0.7);
      
      & fieldset {
        border-color: #3b82f6;
      }
    }
  }

  & .MuiOutlinedInput-input {
    color: #f8fafc;
    padding: 16px;
    font-size: 0.95rem;
  }

  & .MuiInputLabel-root {
    color: #94a3b8;
    font-size: 0.95rem;
    
    &.Mui-focused {
      color: #60a5fa;
    }
  }

  & .MuiIconButton-root {
    color: #94a3b8;
    
    &:hover {
      background: rgba(148, 163, 184, 0.1);
    }
  }
`;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3b82f6',
      light: '#60a5fa',
      dark: '#2563eb',
    },
    background: {
      default: '#0f172a',
      paper: 'rgba(17, 25, 40, 0.85)',
    },
    text: {
      primary: '#f8fafc',
      secondary: '#94a3b8',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3b82f6',
      light: '#60a5fa',
      dark: '#2563eb',
    },
    background: {
      default: '#f1f5f9',
      paper: '#ffffff',
    },
    text: {
      primary: '#0f172a',
      secondary: '#475569',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

const LoginPage = () => {
  const navigate = useNavigate();
  const [onRequest, setOnRequest] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe") === "true";
    setRememberMe(rememberMeValue);
    localStorage.setItem("theme", "dark");
  }, []);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    localStorage.setItem("rememberMe", event.target.checked);
  };

  const handlePasswordVisibility = () => setShowPassword((prev) => !prev);

  const toggleTheme = () => {
    const newTheme = !darkMode;
    setDarkMode(newTheme);
    localStorage.setItem("theme", newTheme ? "dark" : "light");
  };

  const onSignin = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setUsernameError("Valid email is required");
      setOnRequest(false);
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      setOnRequest(false);
      return;
    }

    setOnRequest(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setUsernameError("");
      setPasswordError("");
      setGeneralError("");
      navigate("/dashboard");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === "auth/invalid-email") setUsernameError(errorMessage);
      else if (errorCode === "auth/wrong-password") setPasswordError(errorMessage);
      else setGeneralError(errorMessage);

      setOnRequest(false);
    }
  };

  const particles = Array.from({ length: 25 }).map((_, i) => ({
    x: Math.random() * 100,
    y: Math.random() * 100,
    size: Math.random() * 12 + 6, 
    duration: Math.random() * 3 + 2,
  }));

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          background: darkMode 
            ? 'linear-gradient(135deg, #0f172a 0%, #1e293b 100%)'
            : 'linear-gradient(135deg, #f1f5f9 0%, #e2e8f0 100%)',
          transition: 'background 0.3s ease',
        }}
      >
        {particles.map((particle, index) => (
          <FloatingParticle
            key={index}
            initial={{ x: `${particle.x}vw`, y: `${particle.y}vh`, opacity: 0 }}
            animate={{
              y: [`${particle.y}vh`, `${particle.y - 20}vh`],
              opacity: [0, 0.7, 0],
            }}
            transition={{
              duration: particle.duration,
              repeat: Infinity,
              ease: "linear",
            }}
            style={{
              width: `${particle.size}px`,
              height: `${particle.size}px`,
            }}
          />
        ))}

        <form onSubmit={onSignin}>
          <GlassBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <img src={images.logo} alt="Logo" height={60} style={{ marginBottom: 24 }} />
            </motion.div>
            
            <Typography 
              variant="h4" 
              fontWeight="600" 
              mb={4} 
              sx={{ 
                color: darkMode ? '#f8fafc' : '#0f172a',
                letterSpacing: '-0.02em'
              }}
            >
              Welcome Back
            </Typography>

            <IconButton 
              onClick={toggleTheme} 
              sx={{ 
                position: 'absolute',
                top: '24px',
                right: '24px',
                color: darkMode ? '#94a3b8' : '#475569',
                '&:hover': {
                  background: darkMode ? 'rgba(148, 163, 184, 0.1)' : 'rgba(71, 85, 105, 0.1)',
                }
              }}
            >
              {darkMode ? <LightMode /> : <DarkMode />}
            </IconButton>

            <Stack spacing={3}>
              <StyledTextField
                name="email"
                label="Email"
                fullWidth
                error={!!usernameError}
                helperText={usernameError}
              />
              <StyledTextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                      sx={{
                        color: '#94a3b8',
                        '&.Mui-checked': { color: '#3b82f6' },
                        '&:hover': { background: 'rgba(148, 163, 184, 0.1)' },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ color: darkMode ? '#94a3b8' : '#475569' }}>
                      Remember me
                    </Typography>
                  }
                />
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
                  color: "#ffffff",
                  fontWeight: "600",
                  padding: "14px",
                  fontSize: "1rem",
                  textTransform: "none",
                  borderRadius: "12px",
                  boxShadow: '0 4px 6px -1px rgba(59, 130, 246, 0.2), 0 2px 4px -2px rgba(59, 130, 246, 0.1)',
                  '&:hover': {
                    background: 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 6px 8px -1px rgba(59, 130, 246, 0.2), 0 4px 6px -2px rgba(59, 130, 246, 0.1)',
                  },
                  '&:active': {
                    transform: 'translateY(0)',
                  },
                }}
              >
                Sign In
              </Button>
              {generalError && (
                <Typography 
                  color="error" 
                  sx={{ 
                    mt: 2,
                    fontSize: '0.875rem',
                    textAlign: 'center'
                  }}
                >
                  {generalError}
                </Typography>
              )}
            </Stack>
            {onRequest && (
              <CircularProgress
                size={24}
                sx={{
                  mt: 3,
                  color: '#3b82f6',
                }}
              />
            )}
          </GlassBox>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default LoginPage;
