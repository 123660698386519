import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : true;
  });

  const themes = {
    dark: {
      primary: {
        main: '#1a237e',
        gradient: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
        text: '#ffffff',
        accent: '#90caf9'
      },
      secondary: {
        main: '#2196F3',
        gradient: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      },
      background: {
        default: '#0a1929',
        paper: 'rgba(255, 255, 255, 0.1)',
        paperHover: 'rgba(255, 255, 255, 0.15)'
      },
      text: {
        primary: '#ffffff',
        secondary: '#90caf9',
        hint: 'rgba(255, 255, 255, 0.7)'
      }
    },
    light: {
      primary: {
        main: '#1565c0',
        gradient: 'linear-gradient(135deg, #1565c0 0%, #42a5f5 100%)',
        text: '#ffffff',
        accent: '#1565c0'
      },
      secondary: {
        main: '#1976d2',
        gradient: 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)',
      },
      background: {
        default: '#f5f5f5',
        paper: 'rgba(255, 255, 255, 0.9)',
        paperHover: 'rgba(255, 255, 255, 1)'
      },
      text: {
        primary: '#1a237e',
        secondary: '#1565c0',
        hint: 'rgba(0, 0, 0, 0.7)'
      }
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(prev => {
      const newTheme = !prev;
      localStorage.setItem('theme', newTheme ? 'dark' : 'light');
      return newTheme;
    });
  };

  const currentTheme = isDarkMode ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, theme: currentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
